import React from 'react'
import { useTranslation } from 'react-i18next'
import { privacyPolicy } from '../../constants'

type Props = {
	className?: string
}

export const PrivacyPolicyLink = ({ className }: Props): JSX.Element => {
	const { t } = useTranslation()
	const privacyClasses = className
		? 'privacy-policy '.concat(className)
		: 'privacy-policy '
	return (
		<div className={privacyClasses}>
			<a
				href={privacyPolicy}
				onClick={() => window.open(privacyPolicy)}
				target='_blank'
				rel='noopener noreferrer'
			>
				{t('common.privacy policy')}
			</a>
		</div>
	)
}
