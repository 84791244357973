import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons'
import React, { ReactElement, useState } from 'react'
import { GoogleAdItem, GoogleAdsGroupItem } from '../../../constants'
import { isNotEmptyArray } from '../../../helpers/fnHelpers'
import { GoogleAdsFilterOptions } from '../../../reducers/ads'
import GoogleAdsItem from './GoogleAdsItem'
import GoogleAdsKeywordItem from './GoogleAdsKeywordItem'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useTranslation } from 'react-i18next'

interface Props {
	//TODO: Change type
	//adGroup: GoogleAdsGroupItem
	adGroup: any
	ads: GoogleAdItem[]
	size: GoogleAdsFilterOptions['size']
}

const GoogleAdsGroup = ({ adGroup, ads, size }: Props): ReactElement => {
	const { t } = useTranslation()
	const [isKeywordsOpen, setIsKeywordOpen] = useState<boolean>(false)
	const [isAdsOpen, setIsAdsOpen] = useState<boolean>(false)
	const [showDetails, setShowDetails] = useState<boolean>(false)

	const valuesToRender = [
		{
			text: t('ads visualization.roas'),
			value: adGroup.ROAS,
		},
		{
			text: t('ads visualization.conversions'),
			value: adGroup.conversions,
		},
		{
			text: t('ads visualization.price per conversion'),
			value: `${adGroup.pricePerConversion}€`,
		},
		{
			text: t('ads visualization.average cpc'),
			value: `${adGroup.averageCPC}€`,
		},
	]

	const renderValues = ({
		text,
		value,
	}: {
		text: string
		value: string | number
	}) => {
		return (
			<div
				key={text}
				className='ads-visualisation-page__content--container__adGroups--item--keyword-item border'
			>
				<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
					{text}
				</p>
				<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
					{value}
				</p>
			</div>
		)
	}
	return size === 'large' ? (
		<div
			key={adGroup.adGroupName}
			className={`ads-visualisation-page__content--container__adGroups--item ${adGroup.color} ${size}`}
		>
			<div
				className={
					'ads-visualisation-page__content--container__campaigns--item-header'
				}
			>
				<span />
				<h6 onClick={() => adGroup.onClick(adGroup)}>
					{adGroup.adGroupName ? adGroup.adGroupName : adGroup.assetGroupName}
				</h6>
				<>
					<span
						className={
							'ads-visualisation-page__content--container__campaigns--item-header edit'
						}
						onClick={() => adGroup.onEditClick(adGroup)}
					>
						<p>View</p>
					</span>
				</>
			</div>
			<div className='ads-visualisation-page__content--container__adGroups--item--keyword'>
				{valuesToRender.map(renderValues)}
			</div>
			{isNotEmptyArray(adGroup.keyword) && (
				<div className='ads-visualisation-page__content--container__adGroups--item--keyword '>
					<div className='ads-visualisation-page__content--container__adGroups--item--keyword-item'>
						<p className='ads-visualisation-page__content--container__adGroups--item--keyword--title'>
							{t('ads visualization.terms used')}
						</p>
						<p className='ads-visualisation-page__content--container__adGroups--item--keyword--title'>
							{t('ads visualization.per click')}
						</p>
					</div>

					{isKeywordsOpen && adGroup.keyword
						? adGroup.keyword.map((keyword: any, index: number) => (
								<GoogleAdsKeywordItem
									size={'medium'}
									key={index}
									keyword={keyword}
								/>
						  ))
						: adGroup.keyword &&
						  adGroup.keyword
								.slice(0, 3)
								.map((keyword: any, index: number) => (
									<GoogleAdsKeywordItem
										size={'medium'}
										key={index}
										keyword={keyword}
									/>
								))}
					{adGroup.keyword && adGroup.keyword.length > 4 && (
						<p
							className='ads-visualisation-page__content--container__adGroups--item--keyword--view-more'
							onClick={() => setIsKeywordOpen(old => !old)}
						>
							{isKeywordsOpen ? t('common.view less') : t('common.view more')}
						</p>
					)}
				</div>
			)}
			{isNotEmptyArray(ads) ? (
				isAdsOpen ? (
					isNotEmptyArray(ads) && (
						<>
							<p
								className='ads-visualisation-page__content--container__adGroups--item--keyword--view-more'
								onClick={() => setIsAdsOpen(old => !old)}
							>
								{isAdsOpen
									? t('ads visualization.hide ads')
									: `${t('ads visualization.view ads')} (${ads.length})`}
							</p>
							<div>
								{ads.map((ad, index) => (
									<GoogleAdsItem size={'medium'} key={index} ad={ad} />
								))}
							</div>
						</>
					)
				) : (
					<p
						className='ads-visualisation-page__content--container__adGroups--item--keyword--view-more'
						onClick={() => setIsAdsOpen(old => !old)}
					>
						{isAdsOpen
							? t('ads visualization.hide ads')
							: `${t('ads visualization.view ads')} (${ads.length})`}
					</p>
				)
			) : null}
		</div>
	) : (
		<div
			key={adGroup.adGroupName}
			className={`ads-visualisation-page__content--container__adGroups--item ${adGroup.color} ${size}`}
		>
			<div
				className={
					'ads-visualisation-page__content--container__campaigns--item-header'
				}
			>
				<span />
				<h6 onClick={() => adGroup.onClick(adGroup)}>{adGroup.adGroupName}</h6>
				<>
					<span
						className={
							'ads-visualisation-page__content--container__campaigns--item-header edit'
						}
						onClick={() => adGroup.onEditClick(adGroup)}
					>
						<p>View</p>
					</span>
				</>
			</div>
			<div
				onClick={() => setShowDetails((old: boolean) => !old)}
				className={`ads-visualisation-page__content--container__campaigns--item--hidden ${
					size === 'medium' ? 'medium' : ''
				}  ${showDetails ? 'open' : ''}`}
			>
				<div className='ads-visualisation-page__content--container__adGroups--item--keyword'>
					{valuesToRender.map(renderValues)}
				</div>
				{isNotEmptyArray(adGroup.keyword) && (
					<div className='ads-visualisation-page__content--container__adGroups--item--keyword'>
						<div className='ads-visualisation-page__content--container__adGroups--item--keyword-item'>
							<p className='ads-visualisation-page__content--container__adGroups--item--keyword--title'>
								{t('ads visualization.terms used')}
							</p>
							<p className='ads-visualisation-page__content--container__adGroups--item--keyword--title'>
								{t('ads visualization.per click')}
							</p>
						</div>

						{isKeywordsOpen && adGroup.keyword
							? adGroup.keyword.map((keyword: any, index: number) => (
									<GoogleAdsKeywordItem
										size={size === 'medium' ? 'medium' : 'small'}
										key={index}
										keyword={keyword}
									/>
							  ))
							: adGroup.keyword &&
							  adGroup.keyword
									.slice(0, 3)
									.map((keyword: any, index: number) => (
										<GoogleAdsKeywordItem
											size={size === 'medium' ? 'medium' : 'small'}
											key={index}
											keyword={keyword}
										/>
									))}
						{adGroup.keyword && adGroup.keyword.length > 4 && (
							<p
								className='ads-visualisation-page__content--container__adGroups--item--keyword--view-more'
								onClick={() => setIsKeywordOpen(old => !old)}
							>
								{isKeywordsOpen ? t('common.view less') : t('common.view more')}
							</p>
						)}
					</div>
				)}
			</div>
			<>
				<p
					className='ads-visualisation-page__content--container__adGroups--item--keyword--view-more'
					onClick={() => setShowDetails((old: boolean) => !old)}
				>
					{showDetails
						? `${t('common.view less')} -`
						: `${t('common.view more')} +`}
				</p>
			</>

			{isNotEmptyArray(ads) ? (
				isAdsOpen ? (
					isNotEmptyArray(ads) && (
						<>
							<p
								className='ads-visualisation-page__content--container__adGroups--item--keyword--view-more'
								onClick={() => setIsAdsOpen(old => !old)}
							>
								{isAdsOpen
									? t('ads visualization.hide ads')
									: `${t('ads visualization.view ads')} (${ads.length})`}
							</p>
							<div>
								{ads.map((ad, index) => (
									<GoogleAdsItem
										size={size === 'medium' ? 'medium' : 'small'}
										key={index}
										ad={ad}
									/>
								))}
							</div>
						</>
					)
				) : (
					<p
						className='ads-visualisation-page__content--container__adGroups--item--keyword--view-more'
						onClick={() => setIsAdsOpen(old => !old)}
					>
						{isAdsOpen
							? t('ads visualization.hide ads')
							: `${t('ads visualization.view ads')} (${ads.length})`}
					</p>
				)
			) : null}
		</div>
	)
}

export default GoogleAdsGroup
