import {
	DATASOURCE_ID,
	DATASOURCE_STATUS,
	canAccessWithSubscriptionLevel,
	ESubscriptionLevel,
	isCompanyOnFreeVersion,
	ETableOptions,
	microsKeys,
} from '../constants'
import moment from 'moment'
import { isSuccessful } from './apiHelpers'
import { SuggestionType, fromMicrons } from './dataTransformer'
import i18n from '../services/i18n'
/**
 * Update array by checking string in that array
 * @param  {[String]} array
 * @param  {String} str
 */
export const addElementToArray = (array, str) => {
	const strIndex = array.findIndex(item => item === str)
	const cloneArray = [...array]
	if (strIndex === -1) {
		cloneArray.push(str)
	} else {
		cloneArray.splice(strIndex, 1)
	}

	return cloneArray
}

export const checkConnectedDataSource = (array, str) => {
	return array.some(element => {
		return element.dataSource === str && element.connected === true
	})
}

/**
 * Remove element from  array by checking string in that array
 * @param  {[String]} array
 * @param  {String} str
 */
export const removeElementFromArray = (array, str) => {
	const strIndex = array.findIndex(item => item === str)
	const cloneArray = [...array]
	if (strIndex !== -1) {
		cloneArray.splice(strIndex, 1)
	}

	return cloneArray
}
const today = moment().subtract(1, 'day')
// .subtract(1.3, 'years')
const formatToday = today.format()
const day90ago = today
	.clone()
	// .subtract(1.3, 'years')
	.subtract(91, 'days')
	.format()
const day60ago = today
	.clone()
	// .subtract(1.3, 'years')
	.subtract(61, 'days')
	.format()

const day30ago = today
	.clone()
	// .subtract(1.3, 'years')
	.subtract(31, 'days')
	.format()
const day14ago = today
	.clone()
	// .subtract(1.3, 'years')
	.subtract(15, 'days')
	.format()
const day7ago = today
	.clone()
	// .subtract(1.3, 'years')
	.subtract(8, 'days')
	.format()

export const query90daysFromToday = {
	from: day90ago,
	to: formatToday,
}
export const query60daysFromToday = {
	from: day60ago,
	to: formatToday,
}
export const query30daysFromToday = {
	from: day30ago,
	to: formatToday,
}
export const query14daysFromToday = {
	from: day14ago,
	to: formatToday,
}
export const query7daysFromToday = {
	from: day7ago,
	to: formatToday,
}
export const queryPeriod = {
	7: query7daysFromToday,
	14: query14daysFromToday,
	30: query30daysFromToday,
	60: query60daysFromToday,
	90: query90daysFromToday,
}

/*
	SEO suggestions require Analytics & Search OR project url, conversion value and conversion rate
	Ads suggestions require Ads, analytics & search
	Cro suggestions require Analytics
	*/

export const suggestionsGeneratableByType = (dataSource, project, company) => {
	return {
		SEO: Boolean(
			(!isCompanyOnFreeVersion(company && company.subscriptionLevel) &&
				dataSource &&
				dataSource.googleAnalyticsTokenExpiredTime &&
				((dataSource.googleAnalyticsViewId &&
					dataSource.googleAnalyticsSiteUrl) ||
					dataSource.googleAnalyticsGa4WebPropertyId)) ||
				(canAccessWithSubscriptionLevel(company && company.subscriptionLevel, [
					ESubscriptionLevel.TRIAL,
					ESubscriptionLevel.BASIC,
					ESubscriptionLevel.PREMIUM20,
					ESubscriptionLevel.PREMIUM50,
					ESubscriptionLevel.ENTERPRISE,
				]) &&
					project &&
					project.projectUrl &&
					project.conversionRate &&
					project.conversionValue),
			// &&
			// !project.isEcommerce
		),
		CRO: Boolean(
			(!isCompanyOnFreeVersion(company && company.subscriptionLevel) &&
				dataSource &&
				dataSource.googleAnalyticsTokenExpiredTime &&
				dataSource.googleAnalyticsViewId &&
				dataSource.googleAnalyticsSiteUrl) ||
				(!isCompanyOnFreeVersion(company && company.subscriptionLevel) &&
					dataSource &&
					dataSource.googleAnalyticsTokenExpiredTime &&
					dataSource.googleAnalyticsGa4WebPropertyId),
		),
		ADS: Boolean(
			!isCompanyOnFreeVersion(company && company.subscriptionLevel) &&
				dataSource &&
				dataSource.googleAdsCustomerId,
		),
		SOME: Boolean(
			!isCompanyOnFreeVersion(company && company.subscriptionLevel) &&
				dataSource &&
				dataSource.facebookAdManagerId,
		),
	}
}

export const getActiveDataSource = dataSources =>
	Array.isArray(dataSources)
		? dataSources.find(source => source.status === DATASOURCE_STATUS.ACTIVE)
		: null

export const checkDataSource = dataSource => {
	return {
		FACEBOOK_PAGE: Boolean(dataSource && dataSource[DATASOURCE_ID.FACEBOOK]),
		FACEBOOK_AD_MANAGER: Boolean(
			dataSource && dataSource[DATASOURCE_ID.FACEBOOK],
		),
		GOOGLE_ADS: Boolean(dataSource && dataSource[DATASOURCE_ID.GOOGLE_ADS]),
		GOOGLE_ANALYTICS: Boolean(
			(dataSource && dataSource[DATASOURCE_ID.GOOGLE_ANALYTICS]) ||
				(dataSource && dataSource[DATASOURCE_ID.GOOGLE_ANALYTICS_GA4]),
		),
		GOOGLE_ANALYTICS_GA4: Boolean(
			dataSource && dataSource[DATASOURCE_ID.GOOGLE_ANALYTICS_GA4],
		),
		GOOGLE_SEARCH: Boolean(
			dataSource && dataSource[DATASOURCE_ID.GOOGLE_SEARCH],
		),
		LINKEDIN_PAGE: Boolean(
			dataSource && dataSource[DATASOURCE_ID.LINKEDIN_PAGE],
		),
	}
}
export const checkDataSourceByType = (dataSource, suggestionType) => {
	switch (suggestionType) {
		case DATASOURCE_ID.FACEBOOK:
			return Boolean(dataSource && dataSource[DATASOURCE_ID.FACEBOOK])
		case DATASOURCE_ID.FACEBOOK_AD_MANAGER:
			return Boolean(dataSource && dataSource[DATASOURCE_ID.FACEBOOK])
		case DATASOURCE_ID.GOOGLE_ADS:
			return Boolean(dataSource && dataSource[DATASOURCE_ID.GOOGLE_ADS])
		case DATASOURCE_ID.GOOGLE_ANALYTICS:
			return Boolean(
				(dataSource && dataSource[DATASOURCE_ID.GOOGLE_ANALYTICS]) ||
					(dataSource && dataSource[DATASOURCE_ID.GOOGLE_ANALYTICS_GA4]),
			)
		case DATASOURCE_ID.GOOGLE_SEARCH:
			return Boolean(dataSource && dataSource[DATASOURCE_ID.GOOGLE_SEARCH])
		default:
			return false
	}
}
export const getBySuggestionType = suggestionType => {
	switch (suggestionType) {
		case SuggestionType.some:
			return DATASOURCE_ID.FACEBOOK
		case SuggestionType.ads:
			return DATASOURCE_ID.GOOGLE_ADS
		case SuggestionType.seo:
			return DATASOURCE_ID.GOOGLE_ANALYTICS
		case SuggestionType.cro:
			return DATASOURCE_ID.GOOGLE_SEARCH
		default:
			return
	}
}
/**
 * Return number in maximum number of decimals defined with numberDecimals
 * .E.g  (1.111111,2)  return 1.11
 * .E.g (1.225,2) return 1.23
 * @param  {number} number
 * @param  {number} numberDecimals
 */
export const formatDecimals = (number, numberDecimals = 2) => {
	const formattedNumber =
		isNaN(number) === true
			? number
			: Math.round(Number(number) * Math.pow(10, numberDecimals)) /
			  Math.pow(10, numberDecimals)
	return isFinite(formattedNumber) ? formattedNumber : 0
}
/**
 * If element not exist in array, it will push element to array
 * If element exist in array, it will remove that element in array
 * Return clone array
 * @param  {any} element
 * @param  {any[]} array
 */
export const pushElementToArray = (element, array) => {
	const cloneArray = [...array]
	const elementIndex = cloneArray.indexOf(element)
	if (elementIndex === -1) {
		cloneArray.push(element)
	} else {
		cloneArray.splice(elementIndex, 1)
	}
	return cloneArray
}
/**
 * @param  {Function} callback
 * @param  {number} ms
 * @param  {number} triesLeft=5
 */
export const asyncInterval = async (callback, ms, triesLeft = 5) => {
	const firstCall = await callback()
	if (isSuccessful(firstCall.payload.status)) {
		return
	}
	return new Promise((resolve, reject) => {
		const interval = setInterval(async () => {
			const res = await callback()
			if (isSuccessful(res.payload.status)) {
				resolve()
				clearInterval(interval)
			} else if (triesLeft <= 1) {
				reject()
				clearInterval(interval)
			}
			triesLeft--
		}, ms)
	})
}

/**
 *
 * @param {Array} array Array of any type
 * @param {number} size How many items per array should be returned
 * @returns Array of arrays based on size and total length
 */

export const chunkArray = (array, size) => {
	const chunkArr = []
	let index = 0
	while (index < array.length) {
		chunkArr.push(array.slice(index, size + index))
		index += size
	}
	return chunkArr
}

export const timeConvert = timeValue => {
	const num = timeValue
	const hours = num / 60
	const fullHours = Math.floor(hours)
	const minutes = (hours - fullHours) * 60
	const roundedMinutes = Math.round(minutes / 10) * 10
	return fullHours
		? `${fullHours} ${
				fullHours > 1 ? 'hours' : 'hour'
		  } and ${roundedMinutes} minutes.`
		: `${roundedMinutes} minutes.`
}
export const isNotEmptyArray = array => Array.isArray(array) && array.length > 0

export const matchKeywordLabelWithKeywordRanking = (
	selectedLabels,
	keywordData,
) => {
	let result = []
	let keywords = []

	selectedLabels.forEach(obj => {
		keywords = [...keywords, ...obj.keywords]
	})
	const uniqueKeywords = [...new Set(keywords)]
	uniqueKeywords.forEach(keyword => {
		keywordData.forEach(obj => {
			if (keyword === obj.keyword) {
				result.push(obj)
			}
		})
	})

	return result
}

export const convertRemToPixels = rem => {
	return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

export const calculateTotalFromArray = (array, key) => {
	if (array.every(item => item[key] == null)) {
		return (0).toFixed(2)
	}
	const averageKeys = ['position']
	const combinedValues = array.reduce((total, current) => {
		total += !isNaN(current[key]) ? current[key] : 0
		return total
	}, 0)
	if (averageKeys.includes(key.replace('Compared', ''))) {
		return (combinedValues / array.length).toFixed(2)
	} else {
		return combinedValues.toFixed(2)
	}
}

export const calculateKeywordLabelAverage = keywordData => {
	const calculateAverage = (data, field) => {
		return (
			data.reduce((total, current) => {
				total += current.ranking[0][field] ? current.ranking[0][field] : 0
				return total
			}, 0) / data.length
		)
	}
	const calculateTotal = (data, field) => {
		return data.reduce((total, current) => {
			total += current.ranking[0][field] ? current.ranking[0][field] : 0
			return total
		}, 0)
	}

	return {
		keyword: i18n.t('common.average total'),
		ranking: [
			{
				id: 99999,
				position: Number(calculateAverage(keywordData, 'position').toFixed(0)),
				date: '',
				searchVolume: Number(
					calculateTotal(keywordData, 'searchVolume').toFixed(0),
				),
				change: Number(calculateAverage(keywordData, 'change').toFixed(0)),
				keywordPageUrl: '',
				clicks: calculateTotal(keywordData, 'clicks'),
				CTR: Number(calculateAverage(keywordData, 'CTR').toFixed(0)),
			},
		],
		type: 1,
		labels: [],
		selected: true,
		averageValue: true,
	}
}
