import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FacebookAdsItem, EFacebookAdsOptions } from '../../../constants'
import Button from '../../../shared/components/Button'
import Input from '../../../shared/components/Input'
import XCloser from '../../../shared/components/XCloser'
import EditIcon from '@mui/icons-material/Edit'
import { SvgIcon } from '@mui/material'

interface Props<T extends FacebookAdsItem> {
	editableFacebookAdItem: FacebookAdsItem | null
	type: EFacebookAdsOptions
	handleSave: any
	handleSaveKeyWord: any
}

const FacebookAdCreativeEditModal = <T extends FacebookAdsItem>({
	editableFacebookAdItem,
	type,
	handleSave,
	handleSaveKeyWord,
}: Props<T>): JSX.Element | null => {
	const [facebookAdItem, setFacebookAdItem] = useState<
		Partial<FacebookAdsItem>
	>({})
	const [primaryTexts, setPrimaryTexts] = useState<string[]>([])
	const [headLines, setHeadLines] = useState<string[]>([])
	const [descriptions, setDescriptions] = useState<string[]>([])
	const { t } = useTranslation()
	const [enableEditing, setEnableEditing] = useState(true)
	const handleUserInput = () => {
		setEnableEditing(!enableEditing)
	}
	if (editableFacebookAdItem === null) {
		return null
	}
	useEffect(() => {
		if (!editableFacebookAdItem) {
			setFacebookAdItem({
				type: type,
			})
		} else {
			const transformAdsItem = _.cloneDeep(editableFacebookAdItem)
			setFacebookAdItem(transformAdsItem)
			setPrimaryTexts(transformAdsItem.adCreative.primaryText)
			setHeadLines(transformAdsItem.adCreative.headlines)
			setDescriptions(transformAdsItem.adCreative.descriptions)
		}
	}, [editableFacebookAdItem])
	const handleArrayChange = (e: any, index: number) => {
		let newArray: string[] = []
		switch (e.target.name) {
			case 'primaryTexts':
				newArray = [...primaryTexts]
				newArray[index] = e.target.value
				setPrimaryTexts(newArray)
				break
			case 'headLines':
				newArray = [...headLines]
				newArray[index] = e.target.value
				setHeadLines(newArray)
				break
			case 'descriptions':
				newArray = [...descriptions]
				newArray[index] = e.target.value
				setDescriptions(newArray)
				break
		}
	}

	const handleChangeInput = (e: any) => {
		const { value, name } = e.target
		setFacebookAdItem({ ...facebookAdItem, [name]: value })
	}

	return (
		<div className={'ads-modal__content'}>
			<div className={'ads-modal__content--container'}>
				<div className='ads-modal__content--container-close'>
					<XCloser onClose={() => editableFacebookAdItem.onEditClick(null)} />
					<div
						className={`ads-modal__content--container-close-edit ${
							enableEditing ? 'active' : ''
						}`}
						onClick={handleUserInput}
					>
						<SvgIcon fontSize='large'>
							<EditIcon />
						</SvgIcon>
					</div>
					<p>
						{facebookAdItem &&
						facebookAdItem.type === EFacebookAdsOptions.CAMPAIGN
							? 'Campaign'
							: facebookAdItem.type === EFacebookAdsOptions.AD_SETS
							? 'Ad set'
							: facebookAdItem.type === EFacebookAdsOptions.AD
							? 'Ad'
							: ''}
					</p>
				</div>
				<div className={'ads-modal__content--container__campaigns--items'}>
					<div
						className={
							'ads-modal__content--container__campaigns--items__container ad-creative'
						}
					>
						<div className='ads-modal__content--container__campaigns--items__container--item facebook-ad-creative'>
							<p className='ads-modal__content--container__campaigns--items__container--item key ad-creative'>
								{t('common.ad creative')}
							</p>
						</div>
						<div className='ads-modal__content--container__campaigns--items__container--item facebook-ad-creative'>
							{/* <p className='ads-modal__content--container__campaigns--items__container--item key ad-creative'>
								{t('common.ads')}
							</p> */}
							<div className='ads-modal__content--container__campaigns--items__container--item ad-name-edit'>
								<Input
									name={'name'}
									value={facebookAdItem.name || ''}
									onChange={handleChangeInput}
									disabled={enableEditing}
								/>
								<Button
									className='button-light-blue ads-modal__content--container__campaigns--items__container--button'
									text={t('common.update keyword')}
									onClick={() => handleSaveKeyWord(facebookAdItem)}
									disabled={enableEditing}
								/>
							</div>
						</div>
						{primaryTexts && primaryTexts.length > 0 ? (
							<div className='ads-modal__content--container__campaigns--items__container--item facebook-ad-creative'>
								<p className='ads-modal__content--container__campaigns--items__container--item key ad-creative'>
									{t('common.primary text')}
								</p>
								{primaryTexts.map((item: any, index) => (
									<div
										className='ads-modal__content--container__campaigns--items__container--item input-holder'
										key={index}
									>
										<Input
											name={'primaryTexts'}
											value={item}
											onChange={(e: any) => handleArrayChange(e, index)}
										/>
									</div>
								))}
							</div>
						) : null}
						{headLines && headLines.length > 0 ? (
							<div className='ads-modal__content--container__campaigns--items__container--item facebook-ad-creative'>
								<p className='ads-modal__content--container__campaigns--items__container--item key ad-creative'>
									{t('common.headline')}
								</p>
								{headLines.map((item: any, index) => (
									<div
										className='ads-modal__content--container__campaigns--items__container--item input-holder'
										key={index}
									>
										<Input
											name={'headLines'}
											value={item}
											onChange={(e: any) => handleArrayChange(e, index)}
										/>
									</div>
								))}
							</div>
						) : null}
						{descriptions && descriptions.length > 0 ? (
							<div className='ads-modal__content--container__campaigns--items__container--item facebook-ad-creative'>
								<p className='ads-modal__content--container__campaigns--items__container--item key ad-creative'>
									{t('common.description')}
								</p>
								{descriptions.map((item: any, index) => (
									<div
										className='ads-modal__content--container__campaigns--items__container--item input-holder'
										key={index}
									>
										<Input
											name={'descriptions'}
											value={item}
											onChange={(e: any) => handleArrayChange(e, index)}
										/>
									</div>
								))}
							</div>
						) : null}
					</div>
					<div className='ads-modal__content--container__campaigns--items__container buttons-container'>
						{/* <Button
							className='button-light-blue ads-modal__content--container__campaigns--items__container--button'
							text={t('common.close')}
							onClick={() => editableFacebookAdItem.onEditClick(null)}
						/> */}
						<Button
							className='button-dark-blue ads-modal__content--container__campaigns--items__container--button'
							text={t('common.save')}
							onClick={() =>
								handleSave(
									facebookAdItem,
									primaryTexts,
									headLines,
									descriptions,
								)
							}
							disabled={enableEditing}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FacebookAdCreativeEditModal
