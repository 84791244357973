import moment from 'moment'
import { AdsVisualisationActions } from '../actions/ads'
import {
	GET_GOOGLE_ADS_AD_GROUPS_BY_CAMPAIGN_SUCCESS,
	GET_GOOGLE_ADS_CAMPAIGNS_SUCCESS,
	GET_GOOGLE_ADS_BY_AD_GROUP_ID_SUCCESS,
	GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_SUCCESS,
	GET_GOOGLE_ADS_ALL_ADS_SUCCESS,
	GOOGLE_ADS_CHANGE_FIELD,
	RESET_GOOGLE_ADS,
	SIGN_OUT,
	RESET_GOOGLE_ADS_FILTERS,
	GET_FACEBOOK_ADS_CAMPAIGN_DATA_SUCCESS,
	FACEBOOK_ADS_CHANGE_FIELD,
	GET_FACEBOOK_ADS_AD_SET_DATA_SUCCESS,
	GET_FACEBOOK_ADS_DATA_SUCCESS,
	RESET_FACEBOOK_ADS_FILTERS,
	RESET_FACEBOOK_ADS,
	ADS_CHANGE_TO_SHOW,
	GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID_SUCCESS,
	RESET_SELECTED_GOOGLE_ADS,
	SET_SELECTED_AD_ITEM,
	GET_PROJECT_BY_ID_SUCCESS,
	GET_AD_GROUPS_BID_MODIFIER_SUCCESS,
	CHANGE_AD_GROUPS_BID_MODIFIER,
	RESET_ADS_STATE,
	CREATE_PROJECT,
	GET_GOOGLE_ADS_CHANGE_EVENTS_SUCCESS,
	GET_GOOGLE_ADS_CAMPAIGNS_ADS_CHANGE_EVENTS_SUCCESS,
	GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_ADS_CHANGE_EVENTS_SUCCESS,
} from '../actions/types'
import {
	GoogleAdItem,
	AdsGoogleCampaignItem,
	GoogleAdsGroupItem,
	EGoogleAdsAvailableValues,
	EFacebookAdsAvailableValues,
	EOperators,
	FacebookAdsItem,
	AdGroupBidModifier,
	GoogleAdsChangeEvent,
} from '../constants'

export enum ESelectedToShow {
	FACEBOOK = 'facebook',
	GOOGLE = 'google',
	BOTH = 'both',
}
export type FacebookAdsFilterOptions = {
	property: EFacebookAdsAvailableValues | null
	operator: EOperators
	value: number | string | null
	from: string | null
	to: string | null
	size: 'large' | 'medium' | 'small'
}
export type GoogleAdsFilterOptions = {
	property: EGoogleAdsAvailableValues | null
	operator: EOperators
	value: number | null
	from: string | null
	to: string | null
	size: 'large' | 'medium' | 'small'
}

export type AdsState = {
	google: {
		selectedAdGroups: GoogleAdsGroupItem[]
		selectedAds: GoogleAdItem[]
		selectedAdItem: GoogleAdItem | GoogleAdsGroupItem | null
		adGroups: GoogleAdsGroupItem[]
		adGroupsAdsChangeEvents: GoogleAdsGroupItem[]
		ads: GoogleAdItem[]
		biddingStrategies: Record<any, any>[]
		campaigns: AdsGoogleCampaignItem[]
		campaignsAdsChangeEvents: AdsGoogleCampaignItem[]
		filter: GoogleAdsFilterOptions
		adsMessage: null | string
		adGroupMessage: null | string
		adGroupsBidModifier: AdGroupBidModifier[]
	}
	facebook: {
		adGroups: FacebookAdsItem[]
		ads: FacebookAdsItem[]
		campaigns: FacebookAdsItem[]
		filter: FacebookAdsFilterOptions
	}
	selectedToShow: ESelectedToShow
	googleChangeEvents: GoogleAdsChangeEvent[]
}

const initialState: AdsState = {
	google: {
		selectedAdGroups: [],
		selectedAds: [],
		selectedAdItem: null,
		adGroups: [],
		adGroupsAdsChangeEvents: [],
		ads: [],
		biddingStrategies: [],
		campaigns: [],
		campaignsAdsChangeEvents: [],
		filter: {
			property: EGoogleAdsAvailableValues.ROAS,
			operator: EOperators['>'],
			value: null,
			from: moment().subtract(2, 'M').toISOString(),
			to: moment().toISOString(),
			size: 'large',
		},
		adsMessage: null,
		adGroupMessage: null,
		adGroupsBidModifier: [],
	},
	facebook: {
		adGroups: [],
		ads: [],
		campaigns: [],
		filter: {
			property: EFacebookAdsAvailableValues.ROAS,
			operator: EOperators['>'],
			value: null,
			from: moment().subtract(2, 'M').toISOString(),
			to: moment().toISOString(),
			size: 'large',
		},
	},
	selectedToShow: ESelectedToShow.GOOGLE,
	googleChangeEvents: [],
}

export const adsReducer = (
	state = initialState,
	action: AdsVisualisationActions,
): typeof initialState => {
	switch (action.type) {
		case GET_GOOGLE_ADS_CAMPAIGNS_SUCCESS:
			return {
				...state,
				google: { ...state.google, campaigns: action.payload.data },
			}
		case GET_GOOGLE_ADS_CAMPAIGNS_ADS_CHANGE_EVENTS_SUCCESS:
			return {
				...state,
				google: {
					...state.google,
					campaignsAdsChangeEvents: action.payload.data,
				},
			}
		case GOOGLE_ADS_CHANGE_FIELD:
			return {
				...state,
				google: {
					...state.google,
					filter: {
						...state.google.filter,
						[action.payload.field]: action.payload.value,
					},
				},
			}
		case GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_SUCCESS:
		case GET_GOOGLE_ADS_AD_GROUPS_BY_CAMPAIGN_SUCCESS: {
			// This is to hide negative keywords from ads visualisation, since we don't want to show them.
			const filteredAdGroups: GoogleAdsGroupItem[] = []
			if (Array.isArray(action.payload.data)) {
				action.payload.data.forEach(adGroup => {
					if (Array.isArray(adGroup.keyword)) {
						adGroup.keyword = adGroup.keyword.filter(
							keyword => keyword.negative !== true,
						)
					}
					filteredAdGroups.push(adGroup)
				})
			}
			return {
				...state,
				google: {
					...state.google,
					adGroups: filteredAdGroups,
				},
			}
		}
		case GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_ADS_CHANGE_EVENTS_SUCCESS: {
			// This is to hide negative keywords from ads visualisation, since we don't want to show them.
			const filteredAdGroups: GoogleAdsGroupItem[] = []
			if (Array.isArray(action.payload.data)) {
				action.payload.data.forEach(adGroup => {
					if (Array.isArray(adGroup.keyword)) {
						adGroup.keyword = adGroup.keyword.filter(
							keyword => keyword.negative !== true,
						)
					}
					filteredAdGroups.push(adGroup)
				})
			}
			return {
				...state,
				google: {
					...state.google,
					adGroupsAdsChangeEvents: filteredAdGroups,
				},
			}
		}
		case GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID_SUCCESS: {
			const updateData: {
				selectedAdGroups: GoogleAdsGroupItem[]
				adGroupMessage: AdsState['google']['adGroupMessage']
			} = {
				selectedAdGroups: action.payload.data,
				adGroupMessage: null,
			}
			if (!action.payload.data.length) {
				updateData.adGroupMessage = 'tasks.no ad group'
			}
			return {
				...state,
				google: {
					...state.google,
					selectedAdItem: action.payload.data && action.payload.data[0],
					...updateData,
				},
			}
		}
		case GET_GOOGLE_ADS_ALL_ADS_SUCCESS:
			return {
				...state,
				google: {
					...state.google,
					ads: action.payload.data,
				},
			}
		case SET_SELECTED_AD_ITEM:
			return {
				...state,
				google: {
					...state.google,
					selectedAdItem: action.payload,
				},
			}
		case GET_GOOGLE_ADS_BY_AD_GROUP_ID_SUCCESS: {
			const updateData: {
				selectedAds: GoogleAdItem[]
				adsMessage: AdsState['google']['adsMessage']
			} = {
				selectedAds: action.payload.data,
				adsMessage: null,
			}
			if (!action.payload.data.length) {
				updateData.adsMessage = 'tasks.no ads'
			}
			return {
				...state,
				google: {
					...state.google,
					...updateData,
				},
			}
		}
		case RESET_GOOGLE_ADS_FILTERS: {
			return {
				...state,
				google: { ...state.google, filter: initialState.google.filter },
			}
		}
		case RESET_GOOGLE_ADS:
			return {
				...state,
				google: { ...initialState.google, filter: state.google.filter },
			}
		case CHANGE_AD_GROUPS_BID_MODIFIER:
			return {
				...state,
				google: { ...state.google, adGroupsBidModifier: action.payload },
			}
		case GET_FACEBOOK_ADS_CAMPAIGN_DATA_SUCCESS:
			return {
				...state,
				facebook: { ...state.facebook, campaigns: action.payload.data },
			}
		case FACEBOOK_ADS_CHANGE_FIELD:
			return {
				...state,
				facebook: {
					...state.facebook,
					filter: {
						...state.facebook.filter,
						[action.payload.field]: action.payload.value,
					},
				},
			}
		case GET_FACEBOOK_ADS_AD_SET_DATA_SUCCESS:
			return {
				...state,
				facebook: {
					...state.facebook,
					adGroups: action.payload.data,
				},
			}
		case GET_FACEBOOK_ADS_DATA_SUCCESS:
			return {
				...state,
				facebook: {
					...state.facebook,
					ads: action.payload.data,
				},
			}
		case RESET_FACEBOOK_ADS_FILTERS: {
			return {
				...state,
				facebook: { ...state.facebook, filter: initialState.facebook.filter },
			}
		}
		case GET_GOOGLE_ADS_CHANGE_EVENTS_SUCCESS: {
			return { ...state, googleChangeEvents: action.payload.data }
		}

		case RESET_FACEBOOK_ADS:
			return {
				...state,
				facebook: {
					...initialState.facebook,
					filter: state.facebook.filter,
				},
			}
		case ADS_CHANGE_TO_SHOW:
			return { ...state, selectedToShow: action.payload.value }
		case RESET_SELECTED_GOOGLE_ADS:
			return {
				...state,
				google: {
					...state.google,
					selectedAdGroups: initialState.google.selectedAdGroups,
					selectedAds: initialState.google.selectedAds,
					adGroupMessage: initialState.google.adGroupMessage,
					adsMessage: initialState.google.adsMessage,
				},
			}
		case CREATE_PROJECT:
		case GET_PROJECT_BY_ID_SUCCESS:
		case SIGN_OUT:
			return { ...initialState }
		case GET_AD_GROUPS_BID_MODIFIER_SUCCESS:
			return {
				...state,
				google: {
					...state.google,
					//TODO: create type
					adGroupsBidModifier: Array.isArray(action.payload.data)
						? action.payload.data.map(
								(bidModifier: any) => bidModifier.ad_group_bid_modifier,
						  )
						: [],
				},
			}
		case RESET_ADS_STATE:
			return {
				...initialState,
			}
		default:
			return state
	}
}

export const adsSelectors = {
	getGoogleCampaigns: (state = initialState): AdsGoogleCampaignItem[] =>
		state.google.campaigns,
	getGoogleCampaignsAdsChangeEvents: (
		state = initialState,
	): AdsGoogleCampaignItem[] => state.google.campaignsAdsChangeEvents,
	getGoogleAdGroups: (state = initialState): GoogleAdsGroupItem[] =>
		state.google.adGroups,
	getGoogleAdGroupsAdsChangeEvents: (
		state = initialState,
	): GoogleAdsGroupItem[] => state.google.adGroupsAdsChangeEvents,
	getGoogleAdGroupsBidModifier: (state = initialState): AdGroupBidModifier[] =>
		state.google.adGroupsBidModifier,
	getGoogleSelectedAdGroups: (state = initialState): GoogleAdsGroupItem[] =>
		state.google.selectedAdGroups,
	getGoogleAds: (state = initialState): GoogleAdItem[] => state.google.ads,
	getGoogleSelectedAds: (state = initialState): GoogleAdItem[] =>
		state.google.selectedAds,
	getGoogleAdsMessages: (
		state = initialState,
	): { adGroupMessage: null | string; adsMessage: null | string } => ({
		adGroupMessage: state.google.adGroupMessage,
		adsMessage: state.google.adsMessage,
	}),
	getSelectedAdItem: (
		state = initialState,
	): GoogleAdItem | GoogleAdsGroupItem | null => state.google.selectedAdItem,
	getGoogleFilters: (state = initialState): GoogleAdsFilterOptions =>
		state.google.filter,
	getFacebookCampaigns: (state = initialState): FacebookAdsItem[] =>
		state.facebook.campaigns,
	getFacebookAdSets: (state = initialState): FacebookAdsItem[] =>
		state.facebook.adGroups,
	getFacebookAds: (state = initialState): FacebookAdsItem[] =>
		state.facebook.ads,
	getFacebookFilters: (state = initialState): FacebookAdsFilterOptions =>
		state.facebook.filter,
	getSelectedToShow: (state = initialState): ESelectedToShow =>
		state.selectedToShow,
	getGoogleChangeEvents: (
		state = initialState,
	): AdsState['googleChangeEvents'] => state.googleChangeEvents,
}
