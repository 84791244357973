import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FacebookAdsItem, EFacebookAdsOptions } from '../../../constants'
import Button from '../../../shared/components/Button'
import Input from '../../../shared/components/Input'
import XCloser from '../../../shared/components/XCloser'
import EditIcon from '@mui/icons-material/Edit'
import { SvgIcon } from '@mui/material'

interface Props<T extends FacebookAdsItem> {
	editableFacebookAdItem: FacebookAdsItem | null
	type: EFacebookAdsOptions
	handleSave: any
}

const FacebookAdsEditModal = <T extends FacebookAdsItem>({
	editableFacebookAdItem,
	type,
	handleSave,
}: Props<T>): JSX.Element | null => {
	const [facebookAdItem, setFacebookAdItem] = useState<
		Partial<FacebookAdsItem>
	>({})
	const { t } = useTranslation()
	if (editableFacebookAdItem === null) {
		return null
	}
	useEffect(() => {
		if (!editableFacebookAdItem) {
			setFacebookAdItem({
				type: type,
			})
		} else {
			const transformAdsItem = _.cloneDeep(editableFacebookAdItem)
			setFacebookAdItem(transformAdsItem)
		}
	}, [editableFacebookAdItem])

	const handleChangeInput = (e: any) => {
		const { value, name } = e.target
		setFacebookAdItem({ ...facebookAdItem, [name]: value })
	}
	const [enableEditing, setEnableEditing] = useState(true)
	const handleUserInput = () => {
		setEnableEditing(!enableEditing)
	}

	const faceBookAdsField = [
		{
			value: 'spend',
			translation: 'spend',
		},
		{
			value: 'frequency',
			translation: 'frequency',
		},
		{
			value: 'CPM',
			translation: 'cpm',
		},
		{
			value: 'costPerConversion',
			translation: 'price per conversion',
		},
		{
			value: 'impressions',
			translation: 'impressions',
		},
		{
			value: 'reach',
			translation: 'reach',
		},
		{
			value: 'ROAS',
			translation: 'roas',
		},
		{
			value: 'clicks',
			translation: 'clicks',
		},
		{
			value: 'conversions',
			translation: 'conversions',
		},
		{
			value: 'conversionValue',
			translation: 'conversion value',
		},
		{
			value: 'conversionRate',
			translation: 'conversion rate',
		},
		{
			value: 'CPC',
			translation: 'average cpc',
		},
	]
	return (
		<div className={'ads-modal__content'}>
			<div className={'ads-modal__content--container'}>
				<div className='ads-modal__content--container-close'>
					<XCloser onClose={() => editableFacebookAdItem.onEditClick(null)} />
					<div
						className={`ads-modal__content--container-close-edit ${
							enableEditing ? 'active' : ''
						}`}
						onClick={handleUserInput}
					>
						<SvgIcon fontSize='large'>
							<EditIcon />
						</SvgIcon>
					</div>
					<p>
						{facebookAdItem &&
						facebookAdItem.type === EFacebookAdsOptions.CAMPAIGN
							? 'Campaign'
							: facebookAdItem.type === EFacebookAdsOptions.AD_SETS
							? 'Ad set'
							: facebookAdItem.type === EFacebookAdsOptions.AD
							? 'Ad'
							: ''}
					</p>
				</div>
				<div className={'ads-modal__content--container__campaigns--items'}>
					<Input
						name={'name'}
						value={facebookAdItem.name || ''}
						onChange={handleChangeInput}
						label={
							facebookAdItem &&
							facebookAdItem.type === EFacebookAdsOptions.CAMPAIGN
								? 'Campaign name'
								: facebookAdItem.type === EFacebookAdsOptions.AD_SETS
								? 'Ad set name'
								: facebookAdItem.type === EFacebookAdsOptions.AD
								? 'Ad name'
								: ''
						}
						disabled={enableEditing}
					/>
					<div
						className={
							'ads-modal__content--container__campaigns--items__container'
						}
					>
						{facebookAdItem &&
						facebookAdItem.type === EFacebookAdsOptions.CAMPAIGN ? (
							<div className='ads-modal__content--container__campaigns--items__container--item facebook'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{`${t('ads visualization.budget')} €/D`}
								</p>
								<div className='ads-modal__content--container__campaigns--items__container--item input-holder'>
									<Input
										name={'dailyBudget'}
										value={facebookAdItem.dailyBudget?.toString() || ''}
										onChange={handleChangeInput}
										step={0.1}
										type='number'
										disabled={enableEditing}
									/>
								</div>
							</div>
						) : null}
						{faceBookAdsField.map(({ value, translation }) => {
							return (
								<div
									className='ads-modal__content--container__campaigns--items__container--item facebook'
									key={value}
								>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t(`ads visualization.${translation}`)}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
										{facebookAdItem[value as keyof FacebookAdsItem]}
									</p>
								</div>
							)
						})}
					</div>
					<div className='ads-modal__content--container__campaigns--items__container buttons-container'>
						{/* <Button
							className='button-light-blue ads-modal__content--container__campaigns--items__container--button'
							text={t('common.close')}
							onClick={() => editableFacebookAdItem.onEditClick(null)}
						/> */}
						<Button
							className='button-dark-blue ads-modal__content--container__campaigns--items__container--button'
							text={t('common.save')}
							onClick={() => handleSave(facebookAdItem)}
							disabled={enableEditing}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FacebookAdsEditModal
