import React, { PropsWithChildren } from 'react'
import { connect } from 'react-redux'

import { withTranslation, WithTranslation, useTranslation } from 'react-i18next'

import { adsSelectors, DashboaReducerState } from '../../reducers'
import TopNav from '../../shared/components/TopNav'

import GoogleAdsVisualisation from '../GoogleAdsVisualisation/GoogleAdsVisualisation'
import FacebookAdsVisualisation from '../FacebookAdsVisualisation/FacebookAdsVisualisation'
import { ESelectedToShow } from '../../reducers/ads'
import { adsActions } from '../../actions'
import { ROUTER_PATH } from '../../constants'
import Button from '../../shared/components/Button'

type Props = {
	selectedToShow: ESelectedToShow
	changeToShow: (selected: { value: ESelectedToShow }) => void
	history: any
	resetAds: () => void
} & PropsWithChildren<WithTranslation>

const AdsVisualisation = ({
	selectedToShow,
	changeToShow,
	history,
	resetAds,
}: Props) => {
	const { t } = useTranslation()
	const renderSelection = () => {
		switch (selectedToShow) {
			case 'both':
				return (
					<>
						<GoogleAdsVisualisation {...{ history }} />
						<FacebookAdsVisualisation {...{ history }} />
					</>
				)
			case 'facebook':
				return (
					<>
						<FacebookAdsVisualisation {...{ fullSize: true, history }} />
					</>
				)
			case 'google':
				return (
					<>
						<GoogleAdsVisualisation {...{ fullSize: true, history }} />
					</>
				)

			default:
				break
		}
	}

	const toShowSelection = Object.values(ESelectedToShow)

	const changeSelectedButton = (
		<div
			key={'selection'}
			className='ads-visualisation-page__content--container__selectors--item--options'
		>
			{toShowSelection.map(selection => {
				return (
					<p
						className={`ads-visualisation-page__content--container__selectors--item--options--item ${
							selection === selectedToShow ? 'active' : ''
						} nav-selector`}
						onClick={(e: any) => changeToShow({ value: selection })}
						key={selection}
					>
						{selection !== ESelectedToShow.BOTH
							? selection
							: t('ads visualization.both')}
					</p>
				)
			})}
		</div>
	)

	const toAllogator = () => {
		history.push(ROUTER_PATH.ROAS_ALLOGATOR)
	}

	const ResetButton = (
		<Button key={'roas'} onClick={() => resetAds()} text='Clear data' />
	)

	return (
		<div className='ads-visualisation-page'>
			<TopNav
				title='Ads visualisation'
				customButtons={[changeSelectedButton, ResetButton]}
			/>
			<div className='ads-visualisation-page__container'>
				{renderSelection()}
			</div>
		</div>
	)
}

export default connect(
	(state: DashboaReducerState) => ({
		selectedToShow: adsSelectors.getSelectedToShow(state.ads),
	}),
	dispatch => ({
		changeToShow: (selected: { value: ESelectedToShow }) =>
			dispatch(adsActions.changeToShowAds(selected)),
		resetAds: () => dispatch(adsActions.resetAdsState()),
	}),
)(withTranslation()(AdsVisualisation))
