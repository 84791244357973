/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next'
// import { Switch } from '@material-ui/core'

import { projectSelectors, userSelectors } from '../../reducers'
import { startLoading, stopLoading } from '../../reducers/loading'
import Button from '../../shared/components/Button'
import Input from '../../shared/components/Input'
import { adsActions } from '../../actions'
import {
	GoogleAdsVisualisationPayload,
	calculateAdsColorValue,
	EOperators,
	FacebookAdsItem,
	EFacebookAdsOptions,
	EFacebookAdsAvailableValues,
	FacebookAdsActionValues,
	isCompanyOnFreeVersion,
	EAdsTypeOptions,
	FacebookAdEditingPayload,
	FaceBookAdCreativePayload,
	FaceBookAdCreativeEditingPayload,
	sortByGreenColor,
	ROUTER_PATH,
} from '../../constants'
import { adsSelectors, FacebookAdsFilterOptions } from '../../reducers/ads'
import SelectWithSearch from '../../shared/components/SelectWithSearch'
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { formatDecimals, isNotEmptyArray } from '../../helpers/fnHelpers'
import FacebookAdsModal from './components/FacebookAdsModal'
import FacebookAdsCampaign from './components/FacebookAdsCampaign'
import FacebookAdsEditModal from './components/FacebookAdsEditModal'
import ToolTipWithAnIcon from '../../shared/components/ToolTipWithAnIcon'
import FacebookAdCreativeEditModal from './components/FacebookAdCreativeEditModal'
import { transformAdCreativeData } from '../../helpers/dataTransformer'
import FacebookAdSet from './components/FacebookAdSet'

type Props = {
	currentProject: any
	getAllFacebookCampaigns: any
	getAllFacebookAdSets: any
	getAllFacebookAds: any
	getFaceBookAdCreative: any
	campaigns: FacebookAdsItem[]
	adSets: FacebookAdsItem[]
	ads: FacebookAdsItem[]
	filters: FacebookAdsFilterOptions
	changeFacebookAdsField: any
	resetFacebookAds: any
	resetFacebookAdsFilters: any
	fullSize?: boolean
	user: any
	company: any
	editFaceBookAds: any
	editFaceBookAdCreative: any
	history: any
} & PropsWithChildren<WithTranslation>

const FacebookAdsVisualisation = ({
	currentProject,
	getAllFacebookCampaigns,
	getAllFacebookAdSets,
	getAllFacebookAds,
	getFaceBookAdCreative,
	campaigns,
	adSets,
	ads,
	filters,
	changeFacebookAdsField,
	resetFacebookAds,
	resetFacebookAdsFilters,
	fullSize,
	user,
	company,
	editFaceBookAds,
	editFaceBookAdCreative,
	history,
}: Props) => {
	const { t, i18n } = useTranslation()
	const projectId = currentProject && currentProject.id
	const [showModal, setShowModal] = useState<boolean>(false)
	const [showFilters, setShowFilters] = useState<boolean>(true)
	const [showCreateModal, setCreateShowModal] = useState<boolean>(false)
	const [selectedItem, setSelectedItem] = useState<FacebookAdsItem | null>(null)
	const [showAdCreativeEditModal, setShowAdCreativeEditModal] =
		useState<boolean>(false)
	const hasDataSource = (): boolean => {
		const adsManagerId =
			currentProject.dataSources[0]?.facebookAdManagerId || ''
		const facebookToken = currentProject.dataSources[0]?.facebookToken || ''
		return adsManagerId && facebookToken ? true : false
	}
	const toProjectSettings = () => {
		history.push(ROUTER_PATH.PROJECT_SETTINGS)
	}

	const dispatch = useDispatch()
	const handleItemClick = (handleFacebookAdItemClick: any, setModal: any) => {
		return function handleModalClick(item: FacebookAdsItem) {
			setModal((old: boolean) => !old)
			if (item === null) {
				setTimeout(() => handleFacebookAdItemClick(item), 100)
			} else handleFacebookAdItemClick(item)
		}
	}

	const setOnItemClick = isCompanyOnFreeVersion(company.subscriptionLevel)
		? null
		: handleItemClick(setSelectedItem, setShowModal)
	const setItemEditClick = isCompanyOnFreeVersion(company.subscriptionLevel)
		? null
		: handleItemClick(setSelectedItem, setCreateShowModal)
	const setCreativeAdEditClick = isCompanyOnFreeVersion(
		company.subscriptionLevel,
	)
		? null
		: handleItemClick(setSelectedItem, setShowAdCreativeEditModal)
	const calculateNestedTotal = (
		campaignArray: FacebookAdsItem[],
		field: keyof FacebookAdsItem,
	): string => {
		return (
			campaignArray.reduce((total, current) => {
				if (Array.isArray(current[field]) && current[field].length) {
					total += current[field].reduce(
						(acc: any, curr: FacebookAdsActionValues) => {
							if (curr.action_type === 'omni_purchase') {
								acc += Number(curr.value)
							}
							return acc
						},
						0,
					)
				}
				return total
			}, 0) as unknown as number
		).toFixed(2)
	}

	const calculateTotal = <T,>(campaignArray: T[], field: keyof T): string => {
		return (
			campaignArray.reduce((total, current) => {
				const parsedField =
					typeof current[field] === 'string'
						? parseFloat(current[field] as unknown as string)
						: current[field]
				total += !isNaN(parsedField as any) ? (parsedField as number) : 0
				return total
			}, 0) as unknown as number
		).toFixed(2)
	}

	function addColorsToValues(
		item: FacebookAdsItem,
		type: EFacebookAdsOptions,
	): FacebookAdsItem {
		const value = Object.assign({}, item)
		value.type = type
		// Item size
		value.size = filters.size
		value.onClick = setOnItemClick

		if (item.type === EFacebookAdsOptions.CAMPAIGN) {
			const campaignAdSets = adSets.filter(
				adset => adset.campaignId === value.campaignId,
			)
			value.dailyBudget = value.dailyBudget
				? Number(value.dailyBudget) / 100
				: formatDecimals(
						Number(
							calculateTotal<FacebookAdsItem>(campaignAdSets, 'dailyBudget'),
						) / 100,
				  )
			value.onEditClick = setItemEditClick
		} else if (item.type === EFacebookAdsOptions.AD_SETS) {
			value.dailyBudget = value.dailyBudget
				? Number(value.dailyBudget) / 100
				: formatDecimals(
						Number(calculateTotal<FacebookAdsItem>([value], 'dailyBudget')) /
							100,
				  )
			value.onEditClick = setItemEditClick
		} else if (item.type === EFacebookAdsOptions.AD) {
			value.onEditClick = setCreativeAdEditClick
		}

		value.ROAS = value.actionValues
			? formatDecimals(
					parseFloat(calculateNestedTotal([value], 'actionValues')) /
						parseFloat(calculateTotal<FacebookAdsItem>([value], 'spend')),
			  )
			: 0
		value.conversionRate = value.actionValues
			? formatDecimals(
					parseFloat(calculateNestedTotal([value], 'actions')) /
						parseFloat(calculateTotal<FacebookAdsItem>([value], 'clicks')),
					3,
			  )
			: 0
		value.conversions = value.actions
			? parseInt(calculateNestedTotal([value], 'actions'))
			: 0
		value.conversionValue = value.actions
			? calculateNestedTotal([value], 'actionValues')
			: 0
		value.costPerConversion = value.actions
			? formatDecimals(
					parseFloat(calculateTotal<FacebookAdsItem>([value], 'spend')) /
						parseFloat(calculateNestedTotal([value], 'actions')),
			  )
			: 0
		value.CPC = value.CPC ? Number(value.CPC) : 0
		if (filters.property !== null && filters.value !== null) {
			if (value[filters.property] !== null) {
				const val = value[filters.property] ?? 0
				value.color = calculateAdsColorValue(
					typeof val === 'number' ? val : Number(val) || 0,
					filters.operator,
					filters.value,
				)
			}
		}
		return value
	}

	const sizeOptions = ['large', 'medium', 'small']

	const mappedCampaigns = campaigns
		.map(campaign => addColorsToValues(campaign, EFacebookAdsOptions.CAMPAIGN))
		.sort(sortByGreenColor)
	const mappedAdSets = adSets
		.map(adGroup => addColorsToValues(adGroup, EFacebookAdsOptions.AD_SETS))
		.sort(sortByGreenColor)
	const mappedAds = ads.map(ad => addColorsToValues(ad, EFacebookAdsOptions.AD))

	const filteredAdSets = mappedAdSets.reduce((withoutCampaignId, current) => {
		const foundCampaign = campaigns.find(
			camp => camp.campaignId === current.campaignId,
		)
		if (!foundCampaign) {
			withoutCampaignId.push(current)
		}
		return withoutCampaignId
	}, [] as FacebookAdsItem[])

	const handleClick = async () => {
		const getFacebookAdsData = async () => {
			try {
				dispatch(startLoading())
				resetFacebookAds()
				await getAllFacebookCampaigns(projectId, {
					fromDate: filters.from,
					toDate: filters.to,
				})
				await getAllFacebookAdSets(projectId, {
					fromDate: filters.from,
					toDate: filters.to,
				})
				await getAllFacebookAds(projectId, {
					fromDate: filters.from,
					toDate: filters.to,
				})
			} catch (error) {
				dispatch(stopLoading())
			} finally {
				dispatch(stopLoading())
			}
		}
		getFacebookAdsData()
	}

	const getCampaignData = (
		campaignId: string | null,
		adSetsToCheck: FacebookAdsItem[],
		adsToCheck: FacebookAdsItem[],
	) => {
		const filteredadSets = adSetsToCheck.filter(
			adGroup => adGroup.campaignId === campaignId,
		)
		const filteredAds = adsToCheck.filter(item => {
			const found = filteredadSets.find(adg => adg.adSetId === item.adSetId)
			return !!found
		})

		return { filteredadSets, ads: filteredAds }
	}

	const operators = [
		{ value: EOperators['<'], label: EOperators['<'] },
		{ value: EOperators['<='], label: EOperators['<='] },
		{ value: EOperators['='], label: EOperators['='] },
		{ value: EOperators['>'], label: EOperators['>'] },
		{ value: EOperators['>='], label: EOperators['>='] },
	]
	const availableValues = [
		{
			value: EFacebookAdsAvailableValues.ROAS,
			label: t('ads visualization.roas'),
		},
		{
			value: EFacebookAdsAvailableValues.CONVERSIONS,
			label: t('ads visualization.conversion'),
		},
		{
			value: EFacebookAdsAvailableValues.COST_PER_CONVERSION,
			label: t('ads visualization.price per conversion'),
		},
		{
			value: EFacebookAdsAvailableValues.SPEND,
			label: t('ads visualization.spend'),
		},
		{
			value: EFacebookAdsAvailableValues.CPC,
			label: t('ads visualization.average cpc'),
		},
	]

	const handleChangeField = (e: any, field: any) => {
		if (field === 'to' || field === 'from') {
			changeFacebookAdsField({ field: field, value: e })
		} else {
			const { value } = e
			changeFacebookAdsField({ field: field, value: value })
		}
	}
	const handleChangeInput = (e: any, field: any) => {
		const { value } = e.target

		e.preventDefault()
		changeFacebookAdsField({ field, value: value })
	}

	useEffect(() => {
		return () => {
			resetFacebookAdsFilters()
		}
	}, [resetFacebookAdsFilters])

	const selectedFilterValue = availableValues.find(
		value => value.value === filters.property,
	)
	const handleSave = async (facebookAdItem: FacebookAdsItem) => {
		try {
			dispatch(startLoading())
			let requestData = {}
			switch (facebookAdItem.type) {
				case EFacebookAdsOptions.CAMPAIGN:
					requestData = {
						id: facebookAdItem.campaignId,
						name: facebookAdItem.name,
						dailyBudget: facebookAdItem.dailyBudget,
					}
					await editFaceBookAds(
						currentProject.id,
						facebookAdItem.type,
						requestData,
					)
					await getAllFacebookCampaigns(projectId, {
						fromDate: filters.from,
						toDate: filters.to,
					})
					break
				case EFacebookAdsOptions.AD_SETS:
					requestData = {
						id: facebookAdItem.adSetId,
						name: facebookAdItem.name,
					}
					await editFaceBookAds(
						currentProject.id,
						facebookAdItem.type,
						requestData,
					)
					await getAllFacebookAdSets(projectId, {
						fromDate: filters.from,
						toDate: filters.to,
					})
					break
				case EFacebookAdsOptions.AD:
					requestData = {
						id: facebookAdItem.adId,
						name: facebookAdItem.name,
					}
					await editFaceBookAds(
						currentProject.id,
						facebookAdItem.type,
						requestData,
					)
					await getAllFacebookAds(projectId, {
						fromDate: filters.from,
						toDate: filters.to,
					})
					break
			}
		} catch (er) {
			console.error(er)
		} finally {
			dispatch(stopLoading())
			setCreateShowModal(false)
		}
	}
	const handleAdCreativeSave = async (
		facebookAdItem: FacebookAdsItem,
		primaryTexts: string[],
		headLines: string[],
		descriptions: string[],
	) => {
		try {
			dispatch(startLoading())
			let adCreativeObject: any = {}
			const res = await getFaceBookAdCreative(projectId, {
				id: facebookAdItem.adCreative.adCreativeId,
				fromDate: filters.from,
				toDate: filters.to,
			})
			adCreativeObject = res.payload.data
			const newData = transformAdCreativeData(
				primaryTexts,
				headLines,
				descriptions,
			)
			adCreativeObject[facebookAdItem.adCreative.typeOfAd].bodies =
				newData.bodies
			adCreativeObject[facebookAdItem.adCreative.typeOfAd].titles =
				newData.titles
			adCreativeObject[facebookAdItem.adCreative.typeOfAd].descriptions =
				newData.descriptions
			// When creating a new ads object we can not have both "image_url" and "image_hash" property in the object
			if (
				adCreativeObject.object_story_spec.video_data.image_hash &&
				adCreativeObject.object_story_spec.video_data.image_url
			) {
				delete adCreativeObject.object_story_spec.video_data.image_hash
			}
			await editFaceBookAdCreative(projectId, {
				adId: facebookAdItem.adId,
				asset_feed_spec: adCreativeObject.asset_feed_spec,
				object_story_spec: adCreativeObject.object_story_spec,
			})
			// After editing the Facebook Ad creative, the ad creative object can be missing from the response
			// This will cause the page to crash when user click the ad edit button to edit again
			// The logic below will send get request until the ad creative object appear in the res (limit is 5 times)
			let hasAdCreativeObj = false
			let adCreativeObjRetries = 0
			const getAd = async () => {
				const getAdRes = await getAllFacebookAds(projectId, {
					fromDate: filters.from,
					toDate: filters.to,
				})
				getAdRes.payload.data.map((item: any) => {
					if (
						item.name === facebookAdItem.name &&
						item.adCreative !== undefined
					) {
						hasAdCreativeObj = true
					} else if (
						item.name === facebookAdItem.name &&
						item.adCreative === undefined
					) {
						adCreativeObjRetries += 1
					}
				})
			}
			while (!hasAdCreativeObj) {
				if (adCreativeObjRetries < 5) {
					await getAd()
				} else {
					//TODO: come up with better way to handle this
					hasAdCreativeObj = true
					throw Error('Ad data is missing.Please press Get data')
				}
			}
		} catch (er) {
			console.error(er)
		} finally {
			dispatch(stopLoading())
			setShowAdCreativeEditModal(false)
		}
	}
	return (
		<>
			{showModal && <FacebookAdsModal facebookAdItem={selectedItem} />}
			{showCreateModal && (
				<FacebookAdsEditModal
					editableFacebookAdItem={selectedItem}
					type={EFacebookAdsOptions.CAMPAIGN}
					handleSave={handleSave}
				/>
			)}
			{showAdCreativeEditModal && (
				<FacebookAdCreativeEditModal
					editableFacebookAdItem={selectedItem}
					type={EFacebookAdsOptions.CAMPAIGN}
					handleSave={handleAdCreativeSave}
					handleSaveKeyWord={handleSave}
				/>
			)}
			<div
				className={`ads-visualisation-page__content--ads ${fullSize && 'full'}`}
			>
				<div className='ads-visualisation-page__content--container selectors'>
					{!hasDataSource() && (
						<div className='ads-visualisation-page__blur-overlay'>
							<div className='ads-visualisation-page__blur-content'>
								<h3>{'No data source connected'}</h3>
								<p>
									{
										'Connect your Facebook Ad Manager data source to visualize your ads data.'
									}
								</p>
								<Button
									text={'Connect Facebook Ad Manager'}
									onClick={toProjectSettings}
								/>
							</div>
						</div>
					)}
					<div className='ads-visualisation-page__content--container--selector--container'>
						<div className='ads-visualisation-page__content--container--selector--container--top-contantainer'>
							<h6 className='ads-visualisation-page__content--container--selector-title'>
								{t('ads visualization.facebook ads')}
							</h6>
							<p
								className='ads-visualisation-page__content--container--selector-title--hide-filters'
								onClick={() => setShowFilters(prev => !prev)}
							>
								{showFilters
									? t('ads visualization.hide filters')
									: t('ads visualization.show filters')}
							</p>
						</div>

						{showFilters && (
							<>
								<div className='ads-visualisation-page__content--container__selectors dates'>
									<h6>{t('ads visualization.date selection')}</h6>
									<div className='ads-visualisation-page__content--container__selectors'>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<div className='ads-visualisation-page__content--container__selectors--date'>
												<p className='ads-visualisation-page__content--container__selectors--item title'>
													{t('ads visualization.start date')}
												</p>
												<KeyboardDatePicker
													value={filters.from}
													onChange={(e: any) => handleChangeField(e, 'from')}
													maxDate={filters.to}
													lang={i18n.language}
												/>
											</div>
											<div className='ads-visualisation-page__content--container__selectors--date'>
												<p className='ads-visualisation-page__content--container__selectors--item title'>
													{t('ads visualization.end date')}
												</p>
												<KeyboardDatePicker
													value={filters.to}
													onChange={(e: any) => handleChangeField(e, 'to')}
													disableFuture
													lang={i18n.language}
												/>
											</div>
										</MuiPickersUtilsProvider>
										<div className='ads-visualisation-page__content--container__selectors--date button'>
											<Button
												text={t('ads visualization.get data')}
												onClick={handleClick}
												toolTipText={t('tooltips.get data explanation')}
												disabled={
													!currentProject.id ||
													isCompanyOnFreeVersion(company.subscriptionLevel)
												}
											/>
										</div>
									</div>
									{/* <div className='ads-visualisation-page__content--container__selectors--date'>
							<Button
								disabled={true}
								text='Create Ad'
								onClick={() => setCreateShowModal(true)}
							/>
						</div> */}
								</div>
							</>
						)}

						{showFilters && isNotEmptyArray(campaigns) && (
							<>
								<div className='ads-visualisation-page__content--container--divider' />
								<div className='ads-visualisation-page__content--container__selectors'>
									<h6 className='ads-visualisation-page__content--container__selectors__title'>
										{'Comparisons'}
									</h6>
									<div className='ads-visualisation-page__content--container__selectors--item property'>
										<p className='ads-visualisation-page__content--container__selectors--item title'>
											{t('ads visualization.property')}
											<ToolTipWithAnIcon
												title={t(
													'tooltips.ads visualization property filter explanation',
												)}
											/>
										</p>
										<SelectWithSearch
											name='value'
											options={availableValues}
											value={filters.property ? selectedFilterValue : null}
											defaultValue={null}
											onChange={(e: any) => handleChangeField(e, 'property')}
										/>
									</div>
									<div className='ads-visualisation-page__content--container__selectors--item operator'>
										<p className='ads-visualisation-page__content--container__selectors--item title'>
											{t('ads visualization.operator')}
											<ToolTipWithAnIcon
												title={t(
													'tooltips.ads visualization property filter explanation',
												)}
											/>
										</p>
										<SelectWithSearch
											name='operator'
											options={operators}
											value={{
												value: filters.operator,
												label: filters.operator,
											}}
											onChange={(e: any) => handleChangeField(e, 'operator')}
										/>
									</div>

									<div className='ads-visualisation-page__content--container__selectors--item value'>
										<p className='ads-visualisation-page__content--container__selectors--item title'>
											{t('ads visualization.value')}
											<ToolTipWithAnIcon
												title={t(
													'tooltips.ads visualization value filter explanation',
												)}
											/>
										</p>
										<Input
											name={'Value'}
											value={filters.value ? filters.value.toString() : ''}
											onChange={(e: React.FormEvent<HTMLInputElement>) =>
												handleChangeInput(e, 'value')
											}
										/>
									</div>
									<div className='ads-visualisation-page__content--container__selectors--item options'>
										<div className='ads-visualisation-page__content--container__selectors--item--options'>
											{sizeOptions.map(size => {
												return (
													<p
														className={`ads-visualisation-page__content--container__selectors--item--options--item ${
															size === filters.size ? 'active' : ''
														}`}
														onClick={(e: any) =>
															handleChangeField({ value: size }, 'size')
														}
														key={size}
													>
														{t(`ads visualization.${size}`)}
													</p>
												)
											})}
										</div>
									</div>
									<div className='ads-visualisation-page__content--container__selectors--button'>
										<Button
											text={t('ads visualization.reset filters')}
											onClick={resetFacebookAdsFilters}
											textClassName='ads-visualisation-page__content--container__selectors--button--button-container'
										/>
									</div>
								</div>
								<div className='ads-visualisation-page__content--container--divider' />
							</>
						)}
					</div>

					{showFilters && isNotEmptyArray(campaigns) && (
						<>
							<div className='ads-visualisation-page__content--container__account-details'>
								<h6>{t('ads visualization.account values')}</h6>
								<div className='ads-visualisation-page__content--container__account-details--items'>
									<div className='ads-visualisation-page__content--container__account-details--items--container'>
										<h5>{t('ads visualization.budget')}</h5>
										<p>{`${formatDecimals(
											(Number(
												calculateTotal<FacebookAdsItem>(
													campaigns,
													'dailyBudget',
												),
											) +
												Number(
													calculateTotal<FacebookAdsItem>(
														adSets,
														'dailyBudget',
													),
												)) /
												100,
										)} € /D`}</p>
									</div>
									<div className='ads-visualisation-page__content--container__account-details--items--container'>
										<h5>{t('ads visualization.costs')}</h5>
										<p>{`${calculateTotal<FacebookAdsItem>(
											campaigns,
											'spend',
										)} €`}</p>
									</div>
									<div className='ads-visualisation-page__content--container__account-details--items--container'>
										<h5>{t('ads visualization.conversions')}</h5>
										<p>
											{parseInt(calculateNestedTotal(campaigns, 'actions'))}
										</p>
									</div>
									<div className='ads-visualisation-page__content--container__account-details--items--container'>
										<h5>{t('ads visualization.roas')}</h5>
										<p>
											{(
												parseFloat(
													calculateNestedTotal(campaigns, 'actionValues'),
												) /
												parseFloat(
													calculateTotal<FacebookAdsItem>(campaigns, 'spend'),
												)
											).toFixed(2)}
										</p>
									</div>
									<div className='ads-visualisation-page__content--container__account-details--items--container'>
										<div className='ads-visualisation-page__content--container__account-details--items--container--title'>
											<h5>{t('ads visualization.price per conversion')}</h5>
											<ToolTipWithAnIcon
												title={
													'Total costs divided by total conversions (rounded to 0 due to small value).'
												}
											/>
										</div>
										<p>
											{`${
												parseFloat(
													calculateNestedTotal(campaigns, 'actions'),
												) !== 0
													? (
															parseFloat(
																calculateTotal<FacebookAdsItem>(
																	campaigns,
																	'spend',
																),
															) /
															parseFloat(
																calculateNestedTotal(campaigns, 'actions'),
															)
													  ).toFixed(2)
													: '0'
											} €`}
										</p>
									</div>
									<div className='ads-visualisation-page__content--container__account-details--items--container'>
										<h5>{t('ads visualization.average cpc')}</h5>
										<p>
											{`${(
												parseFloat(
													calculateTotal<FacebookAdsItem>(campaigns, 'spend'),
												) /
												parseFloat(
													calculateTotal<FacebookAdsItem>(campaigns, 'clicks'),
												)
											).toFixed(2)} €`}
										</p>
									</div>
								</div>
							</div>
						</>
					)}
				</div>

				{mappedCampaigns.length > 0 ? (
					<div className='ads-visualisation-page__content--container'>
						{/* <div className='section-labels'>
							<div className='section-label campaigns'>Campaigns</div>
							{filteredAdSets.length > 0 && (
								<div className={`section-label ad-groups ${filters.size}`}>
									Ad Sets
								</div>
							)}
						</div> */}
						<div className='ads-visualisation-page__content--container__campaigns scrollable'>
							{mappedCampaigns.map(campaign => {
								return (
									<FacebookAdsCampaign
										key={campaign.campaignId}
										campaign={campaign}
										ads={
											getCampaignData(
												campaign.campaignId,
												mappedAdSets,
												mappedAds,
											).ads
										}
										adSets={
											getCampaignData(
												campaign.campaignId,
												mappedAdSets,
												mappedAds,
											).filteredadSets
										}
									/>
								)
							})}
							{filteredAdSets.map(adSet => (
								<FacebookAdsCampaign
									key={`phantom-${adSet.adSetId}`}
									campaign={{
										...adSet,
										campaignId: `phantom-${adSet.adSetId}`,
										name: t('ads visualization.orphaned ad set'),
										type: EFacebookAdsOptions.CAMPAIGN,
										isPhantomCampaign: true, // Add this flag to handle special styling
									}}
									ads={getCampaignData(null, [adSet], mappedAds).ads}
									adSets={[adSet]}
								/>
							))}
						</div>
					</div>
				) : null}
			</div>
		</>
	)
}

export default connect(
	(state: any) => ({
		currentProject: projectSelectors.getProject(state.project),
		campaigns: adsSelectors.getFacebookCampaigns(state.ads),
		adSets: adsSelectors.getFacebookAdSets(state.ads),
		ads: adsSelectors.getFacebookAds(state.ads),
		filters: adsSelectors.getFacebookFilters(state.ads),
		user: userSelectors.getUser(state.user),
		company: userSelectors.getCompany(state.user),
	}),
	dispatch => ({
		getAllFacebookCampaigns: (
			projectId: number,
			{ fromDate, toDate }: GoogleAdsVisualisationPayload,
		) =>
			dispatch(
				adsActions.getFacebookAdsCampaigns(projectId, { fromDate, toDate }),
			),
		getAllFacebookAdSets: (
			projectId: number,
			{ fromDate, toDate }: GoogleAdsVisualisationPayload,
		) =>
			dispatch(
				adsActions.getFacebookAllAdSets(projectId, {
					fromDate,
					toDate,
				}),
			),
		getAllFacebookAds: (
			projectId: number,
			{ fromDate, toDate }: GoogleAdsVisualisationPayload,
		) =>
			dispatch(
				adsActions.getFacebookAdsAllAds(projectId, {
					fromDate,
					toDate,
				}),
			),
		getFaceBookAdCreative: (
			projectId: number,
			{ id, fromDate, toDate }: FaceBookAdCreativePayload,
		) =>
			dispatch(
				adsActions.getFaceBookAdCreative(projectId, {
					id,
					fromDate,
					toDate,
				}),
			),
		changeFacebookAdsField: ({ value, field }: any) =>
			dispatch(adsActions.changeFacebookAdsField({ field, value })),
		resetFacebookAds: () => dispatch(adsActions.resetFacebookAdsState()),
		resetFacebookAdsFilters: () =>
			dispatch(adsActions.resetFacebookAdsFilters()),
		editFaceBookAds: (
			projectId: number,
			type: EAdsTypeOptions,
			data: FacebookAdEditingPayload,
		) => dispatch(adsActions.editFaceBookAds(projectId, type, data)),
		editFaceBookAdCreative: (
			projectId: number,
			data: FaceBookAdCreativeEditingPayload,
		) => dispatch(adsActions.editFaceBookAdCreative(projectId, data)),
	}),
)(withTranslation()(FacebookAdsVisualisation))
