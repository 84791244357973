import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons'
import React, { ReactElement, useState } from 'react'
import { FacebookAdsItem } from '../../../constants'
import { isNotEmptyArray } from '../../../helpers/fnHelpers'
import { FacebookAdsFilterOptions } from '../../../reducers/ads'
import FacebookAdItem from './FacebookAdItem'
import { useTranslation } from 'react-i18next'

interface Props {
	adSet: FacebookAdsItem
	ads: FacebookAdsItem[]
	size: FacebookAdsFilterOptions['size']
}

export default function FacebookAdSet({
	adSet,
	ads,
	size,
}: Props): ReactElement {
	const { t } = useTranslation()
	const [isAdsOpen, setIsAdsOpen] = useState<boolean>(false)
	const [showDetails, setShowDetails] = useState<boolean>(false)

	const valuesToRender = [
		{
			text: t('ads visualization.budget'),
			value: `${adSet.dailyBudget || 0}€/D`,
		},
		{
			text: t('ads visualization.spend'),
			value: `${adSet.spend || 0}€`,
		},
		{
			text: t('ads visualization.frequency'),
			value: adSet.frequency || 0,
		},
		{
			text: t('ads visualization.cpm'),
			value: adSet.CPM || 0,
		},
		{
			text: t('ads visualization.price per conversion'),
			value: `${adSet.costPerConversion || 0}€`,
		},
		{
			text: t('ads visualization.impressions'),
			value: `${adSet.impressions || 0}`,
		},
		{
			text: t('ads visualization.reach'),
			value: `${adSet.reach || 0}`,
		},
		{
			text: t('ads visualization.roas'),
			value: `${adSet.ROAS || 0}`,
		},
		{
			text: t('ads visualization.clicks'),
			value: `${adSet.clicks || 0}`,
		},
		{
			text: t('ads visualization.conversions'),
			value: `${adSet.conversions || 0}`,
		},
		{
			text: t('ads visualization.conversion rate'),
			value: `${adSet.conversionRate || 0}%`,
		},
		{
			text: t('ads visualization.average cpc'),
			value: `${adSet.CPC || 0}€`,
		},
	]

	const renderValues = ({
		text,
		value,
	}: {
		text: string
		value: string | number | null
	}) => {
		return (
			<div className='ads-visualisation-page__content--container__adGroups--item--keyword-item border'>
				<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
					{text}
				</p>
				<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
					{value}
				</p>
			</div>
		)
	}
	const renderContent = () => {
		return <>{valuesToRender.map(renderValues)}</>
	}
	return size === 'large' ? (
		<div
			key={adSet.name}
			className={`ads-visualisation-page__content--container__adGroups--item ${adSet.color} ${size}`}
		>
			<div
				className={
					'ads-visualisation-page__content--container__campaigns--item-header'
				}
			>
				<span />
				<h6 onClick={() => adSet.onClick(adSet)}>{adSet.name}</h6>
				<>
					<span
						className={
							'ads-visualisation-page__content--container__campaigns--item-header edit'
						}
						onClick={() => adSet.onEditClick(adSet)}
					>
						<p>View</p>
					</span>
				</>
			</div>
			<div className='ads-visualisation-page__content--container__adGroups--item--keyword'>
				{renderContent()}
			</div>
			{isNotEmptyArray(ads) ? (
				isAdsOpen ? (
					isNotEmptyArray(ads) && (
						<>
							<p
								className='ads-visualisation-page__content--container__adGroups--item--keyword--view-more'
								onClick={() => setIsAdsOpen(old => !old)}
							>
								{isAdsOpen
									? t('ads visualization.hide ads')
									: `${t('ads visualization.view ads')} (${ads.length})`}
							</p>
							<div>
								{ads.map((ad, index) => (
									<FacebookAdItem size={'medium'} key={index} ad={ad} />
								))}
							</div>
						</>
					)
				) : (
					<p
						className='ads-visualisation-page__content--container__adGroups--item--keyword--view-more'
						onClick={() => setIsAdsOpen(old => !old)}
					>
						{isAdsOpen
							? t('ads visualization.hide ads')
							: `${t('ads visualization.view ads')} (${ads.length})`}
					</p>
				)
			) : null}
		</div>
	) : (
		<div
			key={adSet.name}
			className={`ads-visualisation-page__content--container__adGroups--item ${adSet.color} ${size}`}
		>
			<div
				className={
					'ads-visualisation-page__content--container__campaigns--item-header'
				}
			>
				<span />
				<h6 onClick={() => adSet.onClick(adSet)}>{adSet.name}</h6>
				<>
					<span
						className={
							'ads-visualisation-page__content--container__campaigns--item-header edit'
						}
						onClick={() => adSet.onEditClick(adSet)}
					>
						<p>View</p>
					</span>
				</>
			</div>
			<div
				onClick={() => setShowDetails((old: boolean) => !old)}
				className={`ads-visualisation-page__content--container__campaigns--item--hidden ${
					size === 'medium' ? 'medium' : ''
				}  ${showDetails ? 'open' : ''}`}
			>
				<div className='ads-visualisation-page__content--container__adGroups--item--keyword'>
					{renderContent()}
				</div>
			</div>

			<>
				<p
					className='ads-visualisation-page__content--container__adGroups--item--keyword--view-more'
					onClick={() => setShowDetails((old: boolean) => !old)}
				>
					{showDetails
						? `${t('common.view less')} -`
						: `${t('common.view more')} +`}
				</p>
			</>

			{isNotEmptyArray(ads) ? (
				isAdsOpen ? (
					isNotEmptyArray(ads) && (
						<>
							<p
								className='ads-visualisation-page__content--container__adGroups--item--keyword--view-more'
								onClick={() => setIsAdsOpen(old => !old)}
							>
								{isAdsOpen
									? t('ads visualization.hide ads')
									: `${t('ads visualization.view ads')} (${ads.length})`}
							</p>
							<div>
								{ads.map((ad, index) => (
									<FacebookAdItem
										size={size === 'medium' ? 'medium' : 'small'}
										key={index}
										ad={ad}
									/>
								))}
							</div>
						</>
					)
				) : (
					<p
						className='ads-visualisation-page__content--container__adGroups--item--keyword--view-more'
						onClick={() => setIsAdsOpen(old => !old)}
					>
						{isAdsOpen
							? t('ads visualization.hide ads')
							: `${t('ads visualization.view ads')} (${ads.length})`}
					</p>
				)
			) : null}
		</div>
	)
}
