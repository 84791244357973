/**
 * Declare all types and export
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

// Login

export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS'
export const GET_CURRENT_USER_FAIL = 'GET_CURRENT_USER_FAIL'

export const GET_CURRENT_USER_FIREBASE_TOKEN = 'GET_CURRENT_USER_FIREBASE_TOKEN'
export const GET_CURRENT_USER_FIREBASE_TOKEN_SUCCESS =
	'GET_CURRENT_USER_FIREBASE_TOKEN_SUCCESS'
export const GET_CURRENT_USER_FIREBASE_TOKEN_FAIL =
	'GET_CURRENT_USER_FIREBASE_TOKEN_FAIL'

export const GET_COMPANY_USERS = 'GET_COMPANY_USERS'
export const GET_COMPANY_USERS_SUCCESS = 'GET_COMPANY_USERS_SUCCESS'
export const GET_COMPANY_USERS_FAIL = 'GET_COMPANY_USERS_FAIL'

export const SIGN_IN = 'SIGN_IN'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL'

export const SAVE_SITE_URL = 'SAVE_SITE_URL'
export const SAVE_SITE_URL_SUCCESS = 'SAVE_SITE_URL_SUCCESS'
export const SAVE_SITE_URL_FAIL = 'SAVE_SITE_URL_FAIL'

export const GET_AUDIT_SUMMARY_BY_ID = 'GET_AUDIT_SUMMARY_BY_ID'
export const GET_AUDIT_SUMMARY_BY_ID_SUCCESS = 'GET_AUDIT_SUMMARY_BY_ID_SUCCESS'
export const GET_AUDIT_SUMMARY_BY_ID_FAIL = 'GET_AUDIT_SUMMARY_BY_ID_FAIL'

export const RESET_AUDIT_STATE = 'RESET_AUDIT_STATE'
export const SET_SELECTED_AUDIT = 'SET_SELECTED_AUDIT'

export const VIEW_PAGE_DETAILS = 'VIEW_PAGE_DETAILS'
export const VIEW_PAGE_DETAILS_SUCCESS = 'VIEW_PAGE_DETAILS_SUCCESS'
export const VIEW_PAGE_DETAILS_FAIL = 'VIEW_PAGE_DETAILS_FAIL'

export const GET_GOOGLE_ADS_CAMPAIGNS = 'GET_GOOGLE_ADS_CAMPAIGNS'
export const GET_GOOGLE_ADS_CAMPAIGNS_SUCCESS =
	'GET_GOOGLE_ADS_CAMPAIGNS_SUCCESS'
export const GET_GOOGLE_ADS_CAMPAIGNS_FAIL = 'GET_GOOGLE_ADS_CAMPAIGNS_FAIL'

export const GET_GOOGLE_ADS_CAMPAIGNS_ADS_CHANGE_EVENTS =
	'GET_GOOGLE_ADS_CAMPAIGNS_ADS_CHANGE_EVENTS'
export const GET_GOOGLE_ADS_CAMPAIGNS_ADS_CHANGE_EVENTS_SUCCESS =
	'GET_GOOGLE_ADS_CAMPAIGNS_ADS_CHANGE_EVENTS_SUCCESS'
export const GET_GOOGLE_ADS_CAMPAIGNS_ADS_CHANGE_EVENTS_FAIL =
	'GET_GOOGLE_ADS_CAMPAIGNS_ADS_CHANGE_EVENTS_FAIL'

export const GET_AUDITS_BY_PROJECT_ID = 'GET_AUDITS_BY_PROJECT_ID'
export const GET_AUDITS_BY_PROJECT_ID_SUCCESS =
	'GET_AUDITS_BY_PROJECT_ID_SUCCESS'
export const GET_AUDITS_BY_PROJECT_ID_FAIL = 'GET_AUDITS_BY_PROJECT_ID_FAIL'

export const GET_GOOGLE_ADS_AD_GROUPS_BY_CAMPAIGN =
	'GET_GOOGLE_ADS_AD_GROUPS_BY_CAMPAIGN'
export const GET_GOOGLE_ADS_AD_GROUPS_BY_CAMPAIGN_SUCCESS =
	'GET_GOOGLE_ADS_AD_GROUPS_BY_CAMPAIGN_SUCCESS'
export const GET_GOOGLE_ADS_AD_GROUPS_BY_CAMPAIGN_FAIL =
	'GET_GOOGLE_ADS_AD_GROUPS_BY_CAMPAIGN_FAIL'

export const GET_GOOGLE_ADS_BY_AD_GROUP_ID = 'GET_GOOGLE_ADS_BY_AD_GROUP_ID'
export const GET_GOOGLE_ADS_BY_AD_GROUP_ID_SUCCESS =
	'GET_GOOGLE_ADS_BY_AD_GROUP_ID_SUCCESS'
export const GET_GOOGLE_ADS_BY_AD_GROUP_ID_FAIL =
	'GET_GOOGLE_ADS_BY_AD_GROUP_ID_FAIL'

export const GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID =
	'GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID'
export const GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID_SUCCESS =
	'GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID_SUCCESS'
export const GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID_FAIL =
	'GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID_FAIL'

export const GET_GOOGLE_ADS_CHANGE_EVENTS = 'GET_GOOGLE_ADS_CHANGE_EVENTS'
export const GET_GOOGLE_ADS_CHANGE_EVENTS_SUCCESS =
	'GET_GOOGLE_ADS_CHANGE_EVENTS_SUCCESS'
export const GET_GOOGLE_ADS_CHANGE_EVENTS_FAIL =
	'GET_GOOGLE_ADS_CHANGE_EVENTS_FAIL'

export const GET_GROUPED_ADS_CAMPAIGN_DATA = 'GET_GROUPED_ADS_CAMPAIGN_DATA'
export const GET_GROUPED_ADS_CAMPAIGN_DATA_SUCCESS =
	'GET_GROUPED_ADS_CAMPAIGN_DATA_SUCCESS'
export const GET_GROUPED_ADS_CAMPAIGN_DATA_FAIL =
	'GET_GROUPED_ADS_CAMPAIGN_DATA_FAIL'

export const ADS_CHANGE_TO_SHOW = 'ADS_CHANGE_TO_SHOW'

export const GOOGLE_ADS_CHANGE_FIELD = 'GOOGLE_ADS_CHANGE_FIELD'
export const SET_SELECTED_AD_ITEM = 'SET_SELECTED_AD_ITEM'
export const RESET_GOOGLE_ADS = 'RESET_GOOGLE_ADS'
export const RESET_GOOGLE_ADS_FILTERS = 'RESET_GOOGLE_ADS_FILTERS'
export const RESET_SELECTED_GOOGLE_ADS = 'RESET_SELECTED_GOOGLE_ADS'

export const GET_GOOGLE_ADS_ALL_ADS = 'GET_GOOGLE_ADS_ALL_ADS'
export const GET_GOOGLE_ADS_ALL_ADS_SUCCESS = 'GET_GOOGLE_ADS_ALL_ADS_SUCCESS'
export const GET_GOOGLE_ADS_ALL_ADS_FAIL = 'GET_GOOGLE_ADS_ALL_ADS_FAIL'

export const GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS =
	'GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS'
export const GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_SUCCESS =
	'GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_SUCCESS'
export const GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_FAIL =
	'GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_FAIL'

export const GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_ADS_CHANGE_EVENTS =
	'GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_ADS_CHANGE_EVENTS'
export const GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_ADS_CHANGE_EVENTS_SUCCESS =
	'GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_ADS_CHANGE_EVENTS_SUCCESS'
export const GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_ADS_CHANGE_EVENTS_FAIL =
	'GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_ADS_CHANGE_EVENTS_FAIL'

export const POST_CREATE_GOOGLE_ADS_CAMPAIGN = 'POST_CREATE_GOOGLE_ADS_CAMPAIGN'
export const POST_CREATE_GOOGLE_ADS_CAMPAIGN_SUCCESS =
	'POST_CREATE_GOOGLE_ADS_CAMPAIGN_SUCCESS'
export const POST_CREATE_GOOGLE_ADS_CAMPAIGN_FAIL =
	'POST_CREATE_GOOGLE_ADS_CAMPAIGN_FAIL'

export const GET_PAGE_DETAILS_BY_AUDIT_ID = 'GET_PAGE_DETAILS_BY_AUDIT_ID'
export const GET_PAGE_DETAILS_BY_AUDIT_ID_SUCCESS =
	'GET_PAGE_DETAILS_BY_AUDIT_ID_SUCCESS'
export const GET_PAGE_DETAILS_BY_AUDIT_ID_FAIL =
	'GET_PAGE_DETAILS_BY_AUDIT_ID_FAIL'

export const GET_REDIRECT_CHAIN_BY_AUDIT_ID = 'GET_REDIRECT_CHAIN_BY_AUDIT_ID'
export const GET_REDIRECT_CHAIN_BY_AUDIT_ID_SUCCESS =
	'GET_REDIRECT_CHAIN_BY_AUDIT_ID_SUCCESS'
export const GET_REDIRECT_CHAIN_BY_AUDIT_ID_FAIL =
	'GET_REDIRECT_CHAIN_BY_AUDIT_ID_FAIL'

export const VIEW_BROKEN_LINKS = 'VIEW_BROKEN_LINKS'
export const VIEW_BROKEN_LINKS_SUCCESS = 'VIEW_BROKEN_LINKS_SUCCESS'
export const VIEW_BROKEN_LINKS_FAIL = 'VIEW_BROKEN_LINKS_FAIL'

export const VIEW_RESOURCES = 'VIEW_RESOURCES'
export const VIEW_RESOURCES_SUCCESS = 'VIEW_RESOURCES_SUCCESS'
export const VIEW_RESOURCES_FAIL = 'VIEW_RESOURCES_FAIL'

export const VIEW_IMAGES = 'VIEW_IMAGES'
export const VIEW_IMAGES_SUCCESS = 'VIEW_IMAGES_SUCCESS'
export const VIEW_IMAGES_FAIL = 'VIEW_IMAGES_FAIL'

export const SIGN_OUT = 'SIGN_OUT'
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS'
export const SIGN_OUT_FAIL = 'SIGN_OUT_FAIL'

export const SIGN_UP = 'SIGN_UP'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL'

export const FORGET_PASSWORD = 'FORGET_PASSWORD'
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL'

export const CHECK_PROJECT_AMOUNT = 'CHECK_PROJECT_AMOUNT'
export const CHECK_PROJECT_AMOUNT_SUCCESS = 'CHECK_PROJECT_AMOUNT_SUCCESS'
export const CHECK_PROJECT_AMOUNT_FAIL = 'CHECK_PROJECT_AMOUNT_FAIL'

export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS'
export const GET_ALL_PROJECTS_SUCCESS = 'GET_ALL_PROJECTS_SUCCESS'
export const GET_ALL_PROJECTS_FAIL = 'GET_ALL_PROJECTS_FAIL'

export const GET_PROJECT_BY_ID = 'GET_PROJECT_BY_ID'
export const GET_PROJECT_BY_ID_SUCCESS = 'GET_PROJECT_BY_ID_SUCCESS'
export const GET_PROJECT_BY_ID_FAIL = 'GET_PROJECT_BY_ID_FAIL'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'

export const AUTH_WITH_FACEBOOK = 'AUTH_WITH_FACEBOOK'
export const AUTH_WITH_FACEBOOK_SUCCESS = 'AUTH_WITH_FACEBOOK_SUCCESS'
export const AUTH_WITH_FACEBOOK_FAIL = 'AUTH_WITH_FACEBOOK_FAIL'

export const AUTH_WITH_GOOGLE = 'AUTH_WITH_GOOGLE'
export const AUTH_WITH_GOOGLE_SUCCESS = 'AUTH_WITH_GOOGLE_SUCCESS'
export const AUTH_WITH_GOOGLE_FAIL = 'AUTH_WITH_GOOGLE_FAIL'

export const CREATE_PROJECT = 'CREATE_PROJECT'
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS'
export const CREATE_PROJECT_FAIL = 'CREATE_PROJECT_FAIL'

export const CREATE_BULK_PROJECTS = 'CREATE_BULK_PROJECTS'
export const CREATE_BULK_PROJECTS_SUCCESS = 'CREATE_BULK_PROJECTS_SUCCESS'
export const CREATE_BULK_PROJECTS_FAIL = 'CREATE_BULK_PROJECTS_FAIL'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

export const UPDATE_SUBSCRIBER_PROJECT = 'UPDATE_SUBSCRIBER_PROJECT'
export const UPDATE_SUBSCRIBER_PROJECT_SUCCESS =
	'UPDATE_SUBSCRIBER_PROJECT_SUCCESS'
export const UPDATE_SUBSCRIBER_PROJECT_FAIL = 'UPDATE_SUBSCRIBER_PROJECT_FAIL'

export const GET_REPORT_DATA = 'GET_REPORT_DATA'
export const GET_REPORT_DATA_SUCCESS = 'GET_REPORT_DATA_SUCCESS'
export const GET_REPORT_DATA_FAIL = 'GET_REPORT_DATA_FAIL'

export const SET_SELECTED_REPORT_COMPONENT = 'SET_SELECTED_REPORT_COMPONENT'
export const DELETE_SELECTED_REPORT_COMPONENT =
	'DELETE_SELECTED_REPORT_COMPONENT'
export const COPY_SELECTED_REPORT_COMPONENT = 'COPY_SELECTED_REPORT_COMPONENT'

export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS'
export const GET_COUNTRIES_FAIL = 'GET_COUNTRIES_FAIL'

export const GET_GOOGLE_ANALYTIC_PAGE = 'GET_GOOGLE_ANALYTIC_PAGE'
export const GET_GOOGLE_ANALYTIC_PAGE_SUCCESS =
	'GET_GOOGLE_ANALYTIC_PAGE_SUCCESS'
export const GET_GOOGLE_ANALYTIC_PAGE_FAIL = 'GET_GOOGLE_ANALYTIC_PAGE_FAIL'

export const GET_GOOGLE_ANALYTIC_DATA = 'GET_GOOGLE_ANALYTIC_DATA'
export const GET_GOOGLE_ANALYTIC_DATA_SUCCESS =
	'GET_GOOGLE_ANALYTIC_DATA_SUCCESS'
export const GET_GOOGLE_ANALYTIC_DATA_FAIL = 'GET_GOOGLE_ANALYTIC_DATA_FAIL'

export const GET_GOOGLE_ANALYTIC_DATA_SESSIONS =
	'GET_GOOGLE_ANALYTIC_DATA_SESSIONS'
export const GET_GOOGLE_ANALYTIC_DATA_SESSIONS_SUCCESS =
	'GET_GOOGLE_ANALYTIC_DATA_SESSIONS_SUCCESS'
export const GET_GOOGLE_ANALYTIC_DATA_SESSIONS_FAIL =
	'GET_GOOGLE_ANALYTIC_DATA_SESSIONS_FAIL'

export const GET_GOOGLE_ANALYTIC_DATA_GOAL_CONVERSION_RATE_ALL =
	'GET_GOOGLE_ANALYTIC_DATA_GOAL_CONVERSION_RATE_ALL'
export const GET_GOOGLE_ANALYTIC_DATA_GOAL_CONVERSION_RATE_ALL_SUCCESS =
	'GET_GOOGLE_ANALYTIC_DATA_GOAL_CONVERSION_RATE_ALL_SUCCESS'
export const GET_GOOGLE_ANALYTIC_DATA_GOAL_CONVERSION_RATE_ALL_FAIL =
	'GET_GOOGLE_ANALYTIC_DATA_GOAL_CONVERSION_RATE_ALL_FAIL'

export const GET_GOOGLE_ANALYTIC_DATA_PAGE_VIEWS =
	'GET_GOOGLE_ANALYTIC_DATA_PAGE_VIEWS'
export const GET_GOOGLE_ANALYTIC_DATA_PAGE_VIEWS_SUCCESS =
	'GET_GOOGLE_ANALYTIC_DATA_PAGE_VIEWS_SUCCESS'
export const GET_GOOGLE_ANALYTIC_DATA_PAGE_VIEWS_FAIL =
	'GET_GOOGLE_ANALYTIC_DATA_PAGE_VIEWS_FAIL'

export const GET_GOOGLE_ANALYTIC_VIEWS = 'GET_GOOGLE_ANALYTIC_VIEWS'
export const GET_GOOGLE_ANALYTIC_VIEWS_SUCCESS =
	'GET_GOOGLE_ANALYTIC_VIEWS_SUCCESS'
export const GET_GOOGLE_ANALYTIC_VIEWS_FAIL = 'GET_GOOGLE_ANALYTIC_VIEWS_FAIL'

export const GET_GOOGLE_ANALYTIC_GA4_PROPERTIES =
	'GET_GOOGLE_ANALYTIC_GA4_PROPERTIES'
export const GET_GOOGLE_ANALYTIC_GA4_PROPERTIES_SUCCESS =
	'GET_GOOGLE_ANALYTIC_GA4_PROPERTIES_SUCCESS'
export const GET_GOOGLE_ANALYTIC_GA4_PROPERTIES_FAIL =
	'GET_GOOGLE_ANALYTIC_GA4_PROPERTIES_FAIL'

export const POST_GOOGLE_ANALYTIC_VIEWS = 'POST_GOOGLE_ANALYTIC_VIEWS'
export const POST_GOOGLE_ANALYTIC_VIEWS_SUCCESS =
	'POST_GOOGLE_ANALYTIC_VIEWS_SUCCESS'
export const POST_GOOGLE_ANALYTIC_VIEWS_FAIL = 'POST_GOOGLE_ANALYTIC_VIEWS_FAIL'

export const POST_GOOGLE_ANALYTIC_GA4_PROPERTY =
	'POST_GOOGLE_ANALYTIC_GA4_PROPERTY'
export const POST_GOOGLE_ANALYTIC_GA4_PROPERTY_SUCCESS =
	'POST_GOOGLE_ANALYTIC_GA4_PROPERTY_SUCCESS'
export const POST_GOOGLE_ANALYTIC_GA4_PROPERTY_FAIL =
	'POST_GOOGLE_ANALYTIC_GA4_PROPERTY_FAIL'

export const GET_GOOGLE_ANALYTICS_GOALS = 'GET_GOOGLE_ANALYTICS_GOALS'
export const GET_GOOGLE_ANALYTICS_GOALS_SUCCESS =
	'GET_GOOGLE_ANALYTICS_GOALS_SUCCESS'
export const GET_GOOGLE_ANALYTICS_GOALS_FAIL = 'GET_GOOGLE_ANALYTICS_GOALS_FAIL'

export const GET_GOOGLE_ANALYTICS_COMPARISON_DATA =
	'GET_GOOGLE_ANALYTICS_COMPARISON_DATA'
export const GET_GOOGLE_ANALYTICS_COMPARISON_DATA_SUCCESS =
	'GET_GOOGLE_ANALYTICS_COMPARISON_DATA_SUCCESS'
export const GET_GOOGLE_ANALYTICS_COMPARISON_DATA_FAIL =
	'GET_GOOGLE_ANALYTICS_COMPARISON_DATA_FAIL'

export const GET_LINKEDIN_PAGES = 'GET_LINKEDIN_PAGES'
export const GET_LINKEDIN_PAGES_SUCCESS = 'GET_LINKEDIN_PAGES_SUCCESS'
export const GET_LINKEDIN_PAGES_FAIL = 'GET_LINKEDIN_PAGES_FAIL'

export const POST_LINKEDIN_PAGES = 'POST_LINKEDIN_PAGES'
export const POST_LINKEDIN_PAGES_SUCCESS = 'POST_LINKEDIN_PAGES_SUCCESS'
export const POST_LINKEDIN_PAGES_FAIL = 'POST_LINKEDIN_PAGES_FAIL'

export const GET_GOOGLE_ANALYTICS_EVENTS = 'GET_GOOGLE_ANALYTICS_EVENTS'
export const GET_GOOGLE_ANALYTICS_EVENTS_SUCCESS =
	'GET_GOOGLE_ANALYTICS_EVENTS_SUCCESS'
export const GET_GOOGLE_ANALYTICS_EVENTS_FAIL =
	'GET_GOOGLE_ANALYTICS_EVENTS_FAIL'

export const POST_GOOGLE_ANALYTICS_GOALS = 'POST_GOOGLE_ANALYTICS_GOALS'
export const POST_GOOGLE_ANALYTICS_GOALS_SUCCESS =
	'POST_GOOGLE_ANALYTICS_GOALS_SUCCESS'
export const POST_GOOGLE_ANALYTICS_GOALS_FAIL =
	'POST_GOOGLE_ANALYTICS_GOALS_FAIL'

export const GET_GOOGLE_ADS_DATA = 'GET_GOOGLE_ADS_DATA'
export const GET_GOOGLE_ADS_DATA_SUCCESS = 'GET_GOOGLE_ADS_DATA_SUCCESS'
export const GET_GOOGLE_ADS_DATA_FAIL = 'GET_GOOGLE_ADS_DATA_FAIL'

export const GET_GOOGLE_ADS_CAMPAIGN_DATA = 'GET_GOOGLE_ADS_CAMPAIGN_DATA'
export const GET_GOOGLE_ADS_CAMPAIGN_DATA_SUCCESS =
	'GET_GOOGLE_ADS_CAMPAIGN_DATA_SUCCESS'
export const GET_GOOGLE_ADS_CAMPAIGN_DATA_FAIL =
	'GET_GOOGLE_ADS_CAMPAIGN_DATA_FAIL'

export const GET_GOOGLE_ADS_AD_GROUP_DATA = 'GET_GOOGLE_ADS_AD_GROUP_DATA'
export const GET_GOOGLE_ADS_AD_GROUP_DATA_SUCCESS =
	'GET_GOOGLE_ADS_AD_GROUP_DATA_SUCCESS'
export const GET_GOOGLE_ADS_AD_GROUP_DATA_FAIL =
	'GET_GOOGLE_ADS_AD_GROUP_DATA_FAIL'

export const FACEBOOK_ADS_CHANGE_FIELD = 'FACEBOOK_ADS_CHANGE_FIELD'
export const RESET_FACEBOOK_ADS = 'RESET_FACEBOOK_ADS'
export const RESET_FACEBOOK_ADS_FILTERS = 'RESET_FACEBOOK_ADS_FILTERS'

export const RESET_ADS_STATE = 'RESET_ADS_STATE'

export const GET_FACEBOOK_ADS_DATA = 'GET_FACEBOOK_ADS_DATA'
export const GET_FACEBOOK_ADS_DATA_SUCCESS = 'GET_FACEBOOK_ADS_DATA_SUCCESS'
export const GET_FACEBOOK_ADS_DATA_FAIL = 'GET_FACEBOOK_ADS_DATA_FAIL'

export const GET_FACEBOOK_ADS_CAMPAIGN_DATA = 'GET_FACEBOOK_ADS_CAMPAIGN_DATA'
export const GET_FACEBOOK_ADS_CAMPAIGN_DATA_SUCCESS =
	'GET_FACEBOOK_ADS_CAMPAIGN_DATA_SUCCESS'
export const GET_FACEBOOK_ADS_CAMPAIGN_DATA_FAIL =
	'GET_FACEBOOK_ADS_CAMPAIGN_DATA_FAIL'

export const GET_FACEBOOK_ADS_AD_SET_DATA = 'GET_FACEBOOK_ADS_AD_SET_DATA'
export const GET_FACEBOOK_ADS_AD_SET_DATA_SUCCESS =
	'GET_FACEBOOK_ADS_AD_SET_DATA_SUCCESS'
export const GET_FACEBOOK_ADS_AD_SET_DATA_FAIL =
	'GET_FACEBOOK_ADS_AD_SET_DATA_FAIL'

export const GET_FACEBOOK_POSTS = 'GET_FACEBOOK_POSTS'

export const EDIT_FACEBOOK_AD = 'EDIT_FACEBOOK_AD'
export const GET_FACEBOOK_AD_CREATIVE = 'GET_FACEBOOK_AD_CREATIVE'
export const EDIT_FACEBOOK_AD_CREATIVE = 'EDIT_FACEBOOK_AD_CREATIVE'
export const GET_GOOGLE_ADS_CHART_DATA = 'GET_GOOGLE_ADS_CHART_DATA'
export const GET_GOOGLE_ADS_CHART_DATA_SUCCESS =
	'GET_GOOGLE_ADS_CHART_DATA_SUCCESS'
export const GET_GOOGLE_ADS_CHART_DATA_FAIL = 'GET_GOOGLE_ADS_CHART_DATA_FAIL'

export const GET_GOOGLE_ADS_VIEWS = 'GET_GOOGLE_ADS_VIEWS'
export const GET_GOOGLE_ADS_VIEWS_SUCCESS = 'GET_GOOGLE_ADS_VIEWS_SUCCESS'
export const GET_GOOGLE_ADS_VIEWS_FAIL = 'GET_GOOGLE_ADS_VIEWS_FAIL'

export const POST_GOOGLE_ADS_VIEWS = 'POST_GOOGLE_ADS_VIEWS'
export const POST_GOOGLE_ADS_VIEWS_SUCCESS = 'POST_GOOGLE_ADS_VIEWS_SUCCESS'
export const POST_GOOGLE_ADS_VIEWS_FAIL = 'POST_GOOGLE_ADS_VIEWS_FAIL'

export const GET_AD_GROUPS_BID_MODIFIER = 'GET_AD_GROUPS_BID_MODIFIER'
export const GET_AD_GROUPS_BID_MODIFIER_SUCCESS =
	'GET_AD_GROUPS_BID_MODIFIER_SUCCESS'
export const CHANGE_AD_GROUPS_BID_MODIFIER = 'CHANGE_AD_GROUPS_BID_MODIFIER'
export const UPDATE_ROAS_ALLOGATOR = 'UPDATE_ROAS_ALLOGATOR'

export const GET_GOOGLE_SEARCH_CONSOLE_DATA_PAGE =
	'GET_GOOGLE_SEARCH_CONSOLE_DATA_PAGE'
export const GET_GOOGLE_SEARCH_CONSOLE_DATA_PAGE_SUCCESS =
	'GET_GOOGLE_SEARCH_CONSOLE_DATA_PAGE_SUCCESS'
export const GET_GOOGLE_SEARCH_CONSOLE_DATA_PAGE_FAIL =
	'GET_GOOGLE_SEARCH_CONSOLE_DATA_PAGE_FAIL'

export const GET_GOOGLE_SEARCH_CONSOLE_DATA_DATE =
	'GET_GOOGLE_SEARCH_CONSOLE_DATA_DATE'
export const GET_GOOGLE_SEARCH_CONSOLE_DATA_DATE_SUCCESS =
	'GET_GOOGLE_SEARCH_CONSOLE_DATA_DATE_SUCCESS'
export const GET_GOOGLE_SEARCH_CONSOLE_DATA_DATE_FAIL =
	'GET_GOOGLE_SEARCH_CONSOLE_DATA_DATE_FAIL'

export const GET_GOOGLE_SEARCH_CONSOLE_SITES = 'GET_GOOGLE_SEARCH_CONSOLE_SITES'
export const GET_GOOGLE_SEARCH_CONSOLE_SITES_SUCCESS =
	'GET_GOOGLE_SEARCH_CONSOLE_SITES_SUCCESS'
export const GET_GOOGLE_SEARCH_CONSOLE_SITES_FAIL =
	'GET_GOOGLE_SEARCH_CONSOLE_SITES_FAIL'

export const POST_GOOGLE_SEARCH_CONSOLE_SITES =
	'POST_GOOGLE_SEARCH_CONSOLE_SITES'
export const POST_GOOGLE_SEARCH_CONSOLE_SITES_SUCCESS =
	'POST_GOOGLE_SEARCH_CONSOLE_SITES_SUCCESS'
export const POST_GOOGLE_SEARCH_CONSOLE_SITES_FAIL =
	'POST_GOOGLE_SEARCH_CONSOLE_SITES_FAIL'

export const GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS =
	'GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS'
export const GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_SUCCESS =
	'GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_SUCCESS'
export const GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_FAIL =
	'GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_FAIL'

export const GET_FACEBOOK_PAGE_INSIGHT_PAGE_ACTIONS =
	'GET_FACEBOOK_PAGE_INSIGHT_PAGE_ACTIONS'
export const GET_FACEBOOK_PAGE_INSIGHT_PAGE_ACTIONS_SUCCESS =
	'GET_FACEBOOK_PAGE_INSIGHT_PAGE_ACTIONS_SUCCESS'
export const GET_FACEBOOK_PAGE_INSIGHT_PAGE_ACTIONS_FAIL =
	'GET_FACEBOOK_PAGE_INSIGHT_PAGE_ACTIONS_FAIL'

export const GET_FACEBOOK_PAGE_INSIGHT_PAGE_REACTIONS =
	'GET_FACEBOOK_PAGE_INSIGHT_PAGE_REACTIONS'
export const GET_FACEBOOK_PAGE_INSIGHT_PAGE_REACTIONS_SUCCESS =
	'GET_FACEBOOK_PAGE_INSIGHT_PAGE_REACTIONS_SUCCESS'
export const GET_FACEBOOK_PAGE_INSIGHT_PAGE_REACTIONS_FAIL =
	'GET_FACEBOOK_PAGE_INSIGHT_PAGE_REACTIONS_FAIL'

export const GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_ONLINE =
	'GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_ONLINE'
export const GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_ONLINE_SUCCESS =
	'GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_ONLINE_SUCCESS'
export const GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_ONLINE_FAIL =
	'GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_ONLINE_FAIL'

export const GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_GENDER_AGE =
	'GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_GENDER_AGE'
export const GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_GENDER_AGE_SUCCESS =
	'GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_GENDER_AGE_SUCCESS'
export const GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_GENDER_AGE_FAIL =
	'GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_GENDER_AGE_FAIL'

export const GET_FACEBOOK_PAGE_SITES = 'GET_FACEBOOK_PAGE_SITES'
export const GET_FACEBOOK_PAGE_SITES_SUCCESS = 'GET_FACEBOOK_PAGE_SITES_SUCCESS'
export const GET_FACEBOOK_PAGE_SITES_FAIL = 'GET_FACEBOOK_PAGE_SITES_FAIL'

export const POST_FACEBOOK_PAGE_SITES = 'POST_FACEBOOK_PAGE_SITES'
export const POST_FACEBOOK_PAGE_SITES_SUCCESS =
	'POST_FACEBOOK_PAGE_SITES_SUCCESS'
export const POST_FACEBOOK_PAGE_SITES_FAIL = 'POST_FACEBOOK_PAGE_SITES_FAIL'

export const GET_FACEBOOK_AD_MANAGERS = 'GET_FACEBOOK_AD_MANAGERS'
export const GET_FACEBOOK_AD_MANAGERS_SUCCESS =
	'GET_FACEBOOK_AD_MANAGERS_SUCCESS'
export const GET_FACEBOOK_AD_MANAGERS_FAIL = 'GET_FACEBOOK_AD_MANAGERS_FAIL'

export const POST_FACEBOOK_AD_MANAGERS = 'POST_FACEBOOK_AD_MANAGERS'
export const POST_FACEBOOK_AD_MANAGERS_SUCCESS =
	'POST_FACEBOOK_AD_MANAGERS_SUCCESS'
export const POST_FACEBOOK_AD_MANAGERS_FAIL = 'POST_FACEBOOK_AD_MANAGERS_FAIL'

export const GENERATE_SUGGESTION_BY_TYPE = 'GENERATE_SUGGESTION_BY_TYPE'
export const GENERATE_SUGGESTION_BY_TYPE_SUCCESS =
	'GENERATE_SUGGESTION_BY_TYPE_SUCCESS'
export const GENERATE_SUGGESTION_BY_TYPE_FAIL =
	'GENERATE_SUGGESTION_BY_TYPE_FAIL'

export const GET_COMPETITOR_DATA = 'GET_COMPETITOR_DATA'
export const GET_COMPETITOR_DATA_SUCCESS = 'GET_COMPETITOR_DATA_SUCCESS'
export const GET_COMPETITOR_DATA_FAIL = 'GET_COMPETITOR_DATA_FAIL'

export const CHECK_GENERATE_STATUS = 'CHECK_GENERATE_STATUS'
export const CHECK_GENERATE_STATUS_SUCCESS = 'CHECK_GENERATE_STATUS_SUCCESS'
export const CHECK_GENERATE_STATUS_FAIL = 'CHECK_GENERATE_STATUS_FAIL'

export const GET_SUGGESTION_BY_TYPE = 'GET_SUGGESTION_BY_TYPE'
export const GET_SUGGESTION_BY_TYPE_SUCCESS = 'GET_SUGGESTION_BY_TYPE_SUCCESS'
export const GET_SUGGESTION_BY_TYPE_FAIL = 'GET_SUGGESTION_BY_TYPE_FAIL'

export const UPDATE_SUGGESTION_ASSIGNEES = 'UPDATE_SUGGESTION_ASSIGNEES'
export const UPDATE_SUGGESTION_ASSIGNEES_SUCCESS =
	'UPDATE_SUGGESTION_ASSIGNEES_SUCCESS'
export const UPDATE_SUGGESTION_ASSIGNEES_FAIL =
	'UPDATE_SUGGESTION_ASSIGNEES_FAIL'

export const UPDATE_TASK_ASSIGNEES = 'UPDATE_TASK_ASSIGNEES'
export const UPDATE_TASK_ASSIGNEES_SUCCESS = 'UPDATE_TASK_ASSIGNEES_SUCCESS'
export const UPDATE_TASK_ASSIGNEES_FAIL = 'UPDATE_TASK_ASSIGNEES_FAIL'

export const POST_GENERATE_SINGLE_SUGGESTION = 'POST_GENERATE_SINGLE_SUGGESTION'
export const POST_GENERATE_SINGLE_SUGGESTION_SUCCESS =
	'POST_GENERATE_SINGLE_SUGGESTION_SUCCESS'
export const POST_GENERATE_SINGLE_SUGGESTION_FAIL =
	'POST_GENERATE_SINGLE_SUGGESTION_FAIL'

export const POST_GENERATE_SUGGESTIONS_BY_KEYWORDS =
	'POST_GENERATE_SUGGESTIONS_BY_KEYWORDS'
export const POST_GENERATE_SUGGESTIONS_BY_KEYWORDS_SUCCESS =
	'POST_GENERATE_SUGGESTIONS_BY_KEYWORDS_SUCCESS'
export const POST_GENERATE_SUGGESTIONS_BY_KEYWORDS_FAIL =
	'POST_GENERATE_SUGGESTIONS_BY_KEYWORDS_FAIL'

export const GET_SUGGESTION_COUNT = 'GET_SUGGESTION_COUNT'
export const GET_SUGGESTION_COUNT_SUCCESS = 'GET_SUGGESTION_COUNT_SUCCESS'
export const GET_SUGGESTION_COUNT_FAIL = 'GET_SUGGESTION_COUNT_FAIL'

export const GET_SUGGESTION_DATA_BY_ID = 'GET_SUGGESTION_DATA_BY_ID'
export const GET_SUGGESTION_DATA_BY_ID_SUCCESS =
	'GET_SUGGESTION_DATA_BY_ID_SUCCESS'
export const GET_SUGGESTION_DATA_BY_ID_FAIL = 'GET_SUGGESTION_DATA_BY_ID_FAIL'

export const GENERATE_TRACKING_DATA = 'GENERATE_TRACKING_DATA'
export const GENERATE_TRACKING_DATA_SUCCESS = 'GENERATE_TRACKING_DATA_SUCCESS'
export const GENERATE_TRACKING_DATA_FAIL = 'GENERATE_TRACKING_DATA_FAIL'

export const GENERATE_TASK_TRACKING_DATA = 'GENERATE_TASK_TRACKING_DATA'
export const GENERATE_TASK_TRACKING_DATA_SUCCESS =
	'GENERATE_TASK_TRACKING_DATA_SUCCESS'
export const GENERATE_TASK_TRACKING_DATA_FAIL =
	'GENERATE_TASK_TRACKING_DATA_FAIL'

export const UNSUBSCRIBE_DATA_SOURCE = 'UNSUBSCRIBE_DATA_SOURCE'
export const UNSUBSCRIBE_DATA_SOURCE_SUCCESS = 'UNSUBSCRIBE_DATA_SOURCE_SUCCESS'
export const UNSUBSCRIBE_DATA_SOURCE_FAIL = 'UNSUBSCRIBE_DATA_SOURCE_FAIL'

export const UPDATE_DATA_SOURCE = 'UPDATE_DATA_SOURCE'
export const UPDATE_DATA_SOURCE_SUCCESS = 'UPDATE_DATA_SOURCE_SUCCESS'
export const UPDATE_DATA_SOURCE_FAIL = 'UPDATE_DATA_SOURCE_FAIL'

export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const SHOW_MESSAGE_SUCCESS = 'SHOW_MESSAGE_SUCCESS'
export const SHOW_MESSAGE_FAIL = 'SHOW_MESSAGE_FAIL'

export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'
export const CLEAR_MESSAGE_SUCCESS = 'CLEAR_MESSAGE_SUCCESS'
export const CLEAR_MESSAGE_FAIL = 'CLEAR_MESSAGE_FAIL'

export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE'
export const CLEAR_ERROR_MESSAGE_SUCCESS = 'CLEAR_ERROR_MESSAGE_SUCCESS'
export const CLEAR_ERROR_MESSAGE_FAIL = 'CLEAR_ERROR_MESSAGE_FAIL'

export const ERROR_MESSAGE = 'ERROR_MESSAGE'

export const SELECT_PROJECT = 'SELECT_PROJECT'

export const GET_PAYMENT_PLANS = 'GET_PAYMENT_PLANS'
export const GET_PAYMENT_PLANS_SUCCESS = 'GET_PAYMENT_PLANS_SUCCESS'
export const GET_PAYMENT_PLANS_FAIL = 'GET_PAYMENT_PLANS_FAIL'

export const SUBSCRIBE_PLAN = 'SUBSCRIBE_PLAN'
export const SUBSCRIBE_PLAN_SUCCESS = 'SUBSCRIBE_PLAN_SUCCESS'
export const SUBSCRIBE_PLAN_FAIL = 'SUBSCRIBE_PLAN_FAIL'

export const FINALISE_PLAN = 'FINALISE_PLAN'
export const FINALISE_PLAN_SUCCESS = 'FINALISE_PLAN_SUCCESS'
export const FINALISE_PLAN_FAIL = 'FINALISE_PLAN_FAIL'

export const GET_COUPON_CODE = 'GET_COUPON_CODE'
export const GET_COUPON_CODE_SUCCESS = 'GET_COUPON_CODE_SUCCESS'
export const GET_COUPON_CODE_FAIL = 'GET_COUPON_CODE_FAIL'

export const GET_PAYMENT_PORTAL = 'GET_PAYMENT_PORTAL'
export const GET_PAYMENT_PORTALSUCCESS = 'GET_PAYMENT_PORTAL_SUCCESS'
export const GET_PAYMENT_PORTAL_FAIL = 'GET_PAYMENT_PORTAL_FAIL'

export const UNSUBSCRIBE_PLAN = 'UNSUBSCRIBE_PLAN'
export const UNSUBSCRIBE_PLAN_SUCCESS = 'UNSUBSCRIBE_PLAN_SUCCESS'
export const UNSUBSCRIBE_PLAN_FAIL = 'UNSUBSCRIBE_PLAN_FAIL'
export const RESET_LOADERS = 'RESET_LOADERS'

export const UPDATE_SUGGESTION_STATUS = 'UPDATE_SUGGESTION_STATUS'
export const UPDATE_SUGGESTION_STATUS_SUCCESS =
	'UPDATE_SUGGESTION_STATUS_SUCCESS'
export const UPDATE_SUGGESTION_STATUS_FAIL = 'UPDATE_SUGGESTION_STATUS_FAIL'

export const EDIT_PROJECT_FIELD = 'EDIT_PROJECT_FIELD'
export const MODIFY_EDIT_PROJECT_FIELD = 'MODIFY_EDIT_PROJECT_FIELD'
export const MODIFY_PROJECT = 'MODIFY_PROJECT'

export const MODIFY_PROJECT_SUCCESS = 'MODIFY_PROJECT_SUCCESS'
export const MODIFY_PROJECT_FAIL = 'MODIFY_PROJECT_FAIL'

export const UPDATE_PROJECT_TYPE = 'UPDATE_PROJECT_TYPE'
export const UPDATE_PROJECT_TYPE_SUCCESS = 'UPDATE_PROJECT_TYPE_SUCCESS'
export const UPDATE_PROJECT_TYPE_FAIL = 'UPDATE_PROJECT_TYPE_FAIL'

export const UPDATE_KEYWORD = 'UPDATE_KEYWORD'
export const UPDATE_KEYWORD_SUCCESS = 'UPDATE_KEYWORD_SUCCESS'
export const UPDATE_KEYWORD_FAIL = 'UPDATE_KEYWORD_FAIL'

export const SAVE_NEW_KEYWORD = 'SAVE_NEW_KEYWORD'
export const SAVE_NEW_KEYWORD_SUCCESS = 'SAVE_NEW_KEYWORD_SUCCESS'
export const SAVE_NEW_KEYWORD_FAIL = 'SAVE_NEW_KEYWORD_FAIL'

export const BULK_SAVE_NEW_KEYWORD = 'BULK_SAVE_NEW_KEYWORD'
export const BULK_SAVE_NEW_KEYWORD_SUCCESS = 'BULK_SAVE_NEW_KEYWORD_SUCCESS'
export const BULK_SAVE_NEW_KEYWORD_FAIL = 'BULK_SAVE_NEW_KEYWORD_FAIL'

export const GET_RANKED_KEYWORDS = 'GET_RANKED_KEYWORDS'
export const GET_RANKED_KEYWORDS_SUCCESS = 'GET_RANKED_KEYWORDS_SUCCESS'
export const GET_RANKED_KEYWORDS_FAIL = 'GET_RANKED_KEYWORDS_FAIL'

export const GET_KEYWORDS = 'GET_KEYWORDS'
export const GET_KEYWORDS_SUCCESS = 'GET_KEYWORDS_SUCCESS'
export const GET_KEYWORDS_FAIL = 'GET_KEYWORDS_FAIL'

export const POST_KEYWORD_DATA = 'POST_KEYWORD_DATA'
export const POST_KEYWORD_DATA_SUCCESS = 'POST_KEYWORD_DATA_SUCCESS'
export const POST_KEYWORD_DATA_FAIL = 'POST_KEYWORD_DATA_FAIL'

export const DELETE_KEYWORD = 'DELETE_KEYWORD'
export const DELETE_KEYWORD_SUCCESS = 'DELETE_KEYWORD_SUCCESS'
export const DELETE_KEYWORD_FAIL = 'DELETE_KEYWORD_FAIL'

export const GET_KEYWORD_RANKING = 'GET_KEYWORD_RANKING'
export const GET_KEYWORD_RANKING_SUCCESS = 'GET_KEYWORD_RANKING_SUCCESS'
export const GET_KEYWORD_RANKING_FAIL = 'GET_KEYWORD_RANKING_FAIL'

export const GET_KEYWORD_RANKING_FOR_CHECKING =
	'GET_KEYWORD_RANKING_FOR_CHECKING'
export const GET_KEYWORD_RANKING_FOR_CHECKING_SUCCESS =
	'GET_KEYWORD_RANKING_FOR_CHECKING_SUCCESS'
export const GET_KEYWORD_RANKING_FOR_CHECKING_FAIL =
	'GET_KEYWORD_RANKING_FOR_CHECKING_FAIL'

export const POST_KEYWORD_RANKING = 'POST_KEYWORD_RANKING'
export const POST_KEYWORD_RANKING_SUCCESS = 'POST_KEYWORD_RANKING_SUCCESS'
export const POST_KEYWORD_RANKING_FAIL = 'POST_KEYWORD_RANKING_FAIL'

export const SAVE_KEYWORD_LABEL = 'SAVE_KEYWORD_LABEL'
export const SAVE_KEYWORD_LABEL_SUCCESS = 'SAVE_KEYWORD_LABEL_SUCCESS'
export const SAVE_KEYWORD_LABEL_FAIL = 'SAVE_KEYWORD_LABEL_FAIL'

export const GET_KEYWORD_LABEL_BY_PROJECT_ID = 'GET_KEYWORD_LABEL_BY_PROJECT_ID'
export const GET_KEYWORD_LABEL_BY_PROJECT_ID_SUCCESS =
	'GET_KEYWORD_LABEL_BY_PROJECT_ID_SUCCESS'
export const GET_KEYWORD_LABEL_BY_PROJECT_ID_FAIL =
	'GET_KEYWORD_LABEL_BY_PROJECT_ID_FAIL'

export const UPDATE_KEYWORD_LABEL_BY_ID = 'UPDATE_KEYWORD_LABEL_BY_ID'
export const UPDATE_KEYWORD_LABEL_BY_ID_SUCCESS =
	'UPDATE_KEYWORD_LABEL_BY_ID_SUCCESS'
export const UPDATE_KEYWORD_LABEL_BY_ID_FAIL = 'UPDATE_KEYWORD_LABEL_BY_ID_FAIL'

export const CLEAR_KEYWORD_RANKING = 'CLEAR_KEYWORD_RANKING'

export const CLEAR_KEYWORDS_STATE = 'CLEAR_KEYWORDS_STATE'

export const UPDATE_SELECTED_KEYWORDS_FOR_LABEL =
	'UPDATE_SELECTED_KEYWORDS_FOR_LABEL'

export const POST_EXTERNAL_LINKS_FOR_SUGGESTION =
	'POST_EXTERNAL_LINKS_FOR_SUGGESTION'
export const POST_EXTERNAL_LINKS_FOR_SUGGESTION_SUCCESS =
	'POST_EXTERNAL_LINKS_FOR_SUGGESTION_SUCCESS'
export const POST_EXTERNAL_LINKS_FOR_SUGGESTION_FAIL =
	'POST_EXTERNAL_LINKS_FOR_SUGGESTION_FAIL'

export const CLEAR_EXTERNAL_LINKS = 'CLEAR_EXTERNAL_LINKS'

export const CLEAR_KEYWORDS = 'CLEAR_KEYWORDS'

export const GET_TOP_KEYWORDS = 'GET_TOP_KEYWORDS'
export const GET_TOP_KEYWORDS_SUCCESS = 'GET_TOP_KEYWORDS_SUCCESS'
export const GET_TOP_KEYWORDS_FAIL = 'GET_TOP_KEYWORDS_FAIL'

export const VALIDATE_GOOGLE_ADS_RESOURCE = 'VALIDATE_GOOGLE_ADS_RESOURCE'
export const VALIDATE_GOOGLE_ADS_RESOURCE_SUCCESS =
	'VALIDATE_GOOGLE_ADS_RESOURCE_SUCCESS'
export const VALIDATE_GOOGLE_ADS_RESOURCE_FAIL =
	'VALIDATE_GOOGLE_ADS_RESOURCE_FAIL'

export const VALIDATE_EDIT_GOOGLE_KEYWORD = 'VALIDATE_EDIT_GOOGLE_KEYWORD'
export const VALIDATE_EDIT_GOOGLE_KEYWORD_SUCCESS =
	'VALIDATE_EDIT_GOOGLE_KEYWORD_SUCCESS'
export const VALIDATE_EDIT_GOOGLE_KEYWORD_FAIL =
	'VALIDATE_EDIT_GOOGLE_KEYWORD_FAIL'

export const SAVE_EDIT_GOOGLE_KEYWORD = 'SAVE_EDIT_GOOGLE_KEYWORD'
export const SAVE_EDIT_GOOGLE_KEYWORD_SUCCESS =
	'SAVE_EDIT_GOOGLE_KEYWORD_SUCCESS'
export const SAVE_EDIT_GOOGLE_KEYWORD_FAIL = 'SAVE_EDIT_GOOGLE_KEYWORD_FAIL'

export const SAVE_GOOGLE_ADS_RESOURCE = 'SAVE_GOOGLE_ADS_RESOURCE'
export const SAVE_GOOGLE_ADS_RESOURCE_SUCCESS =
	'SAVE_GOOGLE_ADS_RESOURCE_SUCCESS'
export const SAVE_GOOGLE_ADS_RESOURCE_FAIL = 'SAVE_GOOGLE_ADS_RESOURCE_FAIL'
export const GET_REPORT_DEFAULT_TEMPLATES = 'GET_REPORT_DEFAULT_TEMPLATES'
export const GET_REPORT_DEFAULT_TEMPLATES_SUCCESS =
	'GET_REPORT_DEFAULT_TEMPLATES_SUCCESS'
export const GET_REPORT_DEFAULT_TEMPLATES_FAIL =
	'GET_REPORT_DEFAULT_TEMPLATES_FAIL'

export const CLEAR_DRAFT_PROJECT_TEMPLATE = 'CLEAR_DRAFT_PROJECT_TEMPLATE'

export const RESET_DASHBOARD_REDUCER = 'RESET_DASHBOARD_REDUCER'

export const SELECT_REPORT_TEMPLATE = 'SELECT_REPORT_TEMPLATE'
export const NEW_CUSTOM_REPORT = 'NEW_CUSTOM_REPORT'
export const UPDATE_REPORT_TEMPLATE = 'UPDATE_REPORT_TEMPLATE'
export const CHANGE_DRAFT_REPORT_TEMPLATE_FIELD =
	'CHANGE_DRAFT_REPORT_TEMPLATE_FIELD'

export const CHANGE_DRAFT_REPORT_PAGE_FIELD = 'CHANGE_DRAFT_REPORT_PAGE_FIELD'

export const SAVE_DRAFT_PROJECT_TEMPLATE = 'SAVE_DRAFT_PROJECT_TEMPLATE'

export const GET_REPORT_TEMPLATES = 'GET_REPORT_TEMPLATES'
export const GET_REPORT_TEMPLATES_SUCCESS = 'GET_REPORT_TEMPLATES_SUCCESS'
export const GET_REPORT_TEMPLATES_FAIL = 'GET_REPORT_TEMPLATES_FAIL'

export const GET_SAVED_CUSTOM_REPORT_TEMPLATES =
	'GET_SAVED_CUSTOM_REPORT_TEMPLATES'
export const GET_SAVED_CUSTOM_REPORT_TEMPLATES_SUCCESS =
	'GET_SAVED_CUSTOM_REPORT_TEMPLATES_SUCCESS'
export const GET_SAVED_CUSTOM_REPORT_TEMPLATES_FAIL =
	'GET_SAVED_CUSTOM_REPORT_TEMPLATES_FAIL'

export const POST_REPORT_TEMPLATE = 'POST_REPORT_TEMPLATE'
export const POST_REPORT_TEMPLATE_SUCCESS = 'POST_REPORT_TEMPLATE_SUCCESS'
export const POST_REPORT_TEMPLATE_FAIL = 'POST_REPORT_TEMPLATE_FAIL'

export const SAVE_CUSTOM_REPORT_TEMPLATE = 'SAVE_CUSTOM_REPORT_TEMPLATE'
export const SAVE_CUSTOM_REPORT_TEMPLATE_SUCCESS =
	'SAVE_CUSTOM_REPORT_TEMPLATE_SUCCESS'
export const SAVE_CUSTOM_REPORT_TEMPLATE_FAIL =
	'SAVE_CUSTOM_REPORT_TEMPLATE_FAIL'

export const POST_UPDATE_REPORT_TEMPLATE = 'POST_UPDATE_REPORT_TEMPLATE'
export const POST_UPDATE_REPORT_TEMPLATE_SUCCESS =
	'POST_UPDATE_REPORT_TEMPLATE_SUCCESS'
export const POST_UPDATE_REPORT_TEMPLATE_FAIL =
	'POST_UPDATE_REPORT_TEMPLATE_FAIL'

export const UPDATE_SAVED_CUSTOM_REPORT_TEMPLATE =
	'UPDATE_SAVED_CUSTOM_REPORT_TEMPLATE'
export const UPDATE_SAVED_CUSTOM_REPORT_TEMPLATE_SUCCESS =
	'UPDATE_SAVED_CUSTOM_REPORT_TEMPLATE_SUCCESS'
export const UPDATE_SAVED_CUSTOM_REPORT_TEMPLATE_FAIL =
	'UPDATE_SAVED_CUSTOM_REPORT_TEMPLATE_FAIL'

export const GET_REPORT_COMPONENT_DATA = 'GET_REPORT_COMPONENT_DATA'
export const GET_REPORT_COMPONENT_DATA_SUCCESS =
	'GET_REPORT_COMPONENT_DATA_SUCCESS'
export const GET_REPORT_COMPONENT_DATA_FAIL = 'GET_REPORT_COMPONENT_DATA_FAIL'

export const GET_CUSTOM_REPORT_DATA = 'GET_CUSTOM_REPORT_DATA'
export const GET_CUSTOM_REPORT_DATA_SUCCESS = 'GET_CUSTOM_REPORT_DATA_SUCCESS'
export const GET_CUSTOM_REPORT_DATA_FAIL = 'GET_CUSTOM_REPORT_DATA_FAIL'

export const GET_AUTOMATE_REPORT_DATA = 'GET_AUTOMATE_REPORT_DATA'
export const GET_GENERATED_MONTHLY_REPORT = 'GET_GENERATED_MONTHLY_REPORT'
export const GET_SAVED_REPORT_FILE = 'GET_SAVED_REPORT_FILE'

export const DELETE_REPORT_TEMPLATE = ' DELETE_REPORT_TEMPLATE'
export const DELETE_REPORT_TEMPLATE_SUCCESS = ' DELETE_REPORT_TEMPLATE_SUCCESS'
export const DELETE_REPORT_TEMPLATE_FAIL = ' DELETE_REPORT_TEMPLATE_FAIL'

export const CLEAR_RAW_DATA_BY_ID = 'CLEAR_RAW_DATA_BY_ID'

export const TUTORIAL_CHANGE_FIELD = 'TUTORIAL_CHANGE_FIELD'
export const CHANGE_STEP = 'CHANGE_STEP'

export const EDIT_DRAFT_PROJECT = 'EDIT_DRAFT_PROJECT'
export const RESET_DRAFT_PROJECT = 'RESET_DRAFT_PROJECT'
export const RESET_PROJECT_STATE = 'RESET_PROJECT_STATE'
export const RESET_EDIT_PROJECT_STATE = 'RESET_EDIT_PROJECT_STATE'
export const CHANGE_USER_FIELD = 'CHANGE_USER_FIELD'
export const CHANGE_COMPANY_FIELD = 'CHANGE_COMPANY_FIELD'
export const CHANGE_SIDENAV_STATUS = 'CHANGE_SIDENAV_STATUS'
export const SET_SIDENAV_STATUS = 'SET_SIDENAV_STATUS'
export const UPLOAD_KEYWORDS_FILE = 'UPLOAD_KEYWORDS_FILE'
export const UPLOAD_KEYWORDS_FILE_SUCCESS = 'UPLOAD_KEYWORDS_FILE_SUCCESS'
export const UPLOAD_KEYWORDS_FILE_FAIL = 'UPLOAD_KEYWORDS_FILE_FAIL'

export const VALIDATE_EDIT_GOOGLE_AD = 'VALIDATE_EDIT_GOOGLE_AD'
export const VALIDATE_EDIT_GOOGLE_AD_SUCCESS = 'VALIDATE_EDIT_GOOGLE_AD_SUCCESS'
export const VALIDATE_EDIT_GOOGLE_AD_FAIL = 'VALIDATE_EDIT_GOOGLE_AD_FAIL'

export const SAVE_EDIT_GOOGLE_AD = 'SAVE_EDIT_GOOGLE_AD'
export const SAVE_EDIT_GOOGLE_AD_SUCCESS = 'SAVE_EDIT_GOOGLE_AD_SUCCESS'
export const SAVE_EDIT_GOOGLE_AD_FAIL = 'SAVE_EDIT_GOOGLE_AD_FAIL'

export const RESET_AI_CONTENT = 'RESET_AI_CONTENT'

export const GET_AI_CONTENT = 'GET_AI_CONTENT'
export const GET_AI_CONTENT_SUCCESS = 'GET_AI_CONTENT_SUCCESS'
export const GET_AI_CONTENT_FAIL = 'GET_AI_CONTENT_FAIL'

export const GET_ALL_READY_MODEL = 'GET_ALL_READY_MODEL'
export const GET_ALL_READY_MODEL_SUCCESS = 'GET_ALL_READY_MODEL_SUCCESS'
export const GET_ALL_READY_MODEL_FAIL = 'GET_ALL_READY_MODEL_FAIL'

export const CREATE_FINETUNE_MODEL = 'CREATE_FINETUNE_MODEL'
export const CREATE_FINETUNE_MODEL_SUCCESS = 'CREATE_FINETUNE_MODEL_SUCCESS'
export const CREATE_FINETUNE_MODEL_FAIL = 'CREATE_FINETUNE_MODEL_FAIL'

export const AI_IMAGE_GENERATOR = 'AI_IMAGE_GENERATOR'
export const AI_IMAGE_GENERATOR_SUCCESS = 'AI_IMAGE_GENERATOR_SUCCESS'
export const AI_IMAGE_GENERATOR_FAIL = 'AI_IMAGE_GENERATOR_FAIL'

export const GENERATE_SUBHEADINGS = 'GENERATE_SUBHEADINGS'
export const GENERATE_SUBHEADINGS_SUCCESS = 'GENERATE_SUBHEADINGS_SUCCESS'
export const GENERATE_SUBHEADINGS_FAIL = 'GENERATE_SUBHEADINGS_FAIL'

export const GENERATE_BLOG_CONTENT = 'GENERATE_BLOG_CONTENT'
export const GENERATE_BLOG_CONTENT_SUCCESS = 'GENERATE_BLOG_CONTENT_SUCCESS'
export const GENERATE_BLOG_CONTENT_FAIL = 'GENERATE_BLOG_CONTENT_FAIL'

export const GET_ALL_AI_ARTICLE = 'GET_ALL_AI_ARTICLE'
export const GET_ALL_AI_ARTICLE_SUCCESS = 'GET_ALL_AI_ARTICLE_SUCCESS'
export const GET_ALL_AI_ARTICLE_FAIL = 'GET_ALL_AI_ARTICLE_FAIL'

export const GET_ALL_AI_CATEGORY_DESCRIPTION = 'GET_ALL_AI_CATEGORY_DESCRIPTION'
export const GET_ALL_AI_CATEGORY_DESCRIPTION_SUCCESS =
	'GET_ALL_AI_CATEGORY_DESCRIPTION_SUCCESS'
export const GET_ALL_AI_CATEGORY_DESCRIPTION_FAIL =
	'GET_ALL_AI_CATEGORY_DESCRIPTION_FAIL'

export const GET_AI_PRODUCT_DESCRIPTION = 'GET_AI_PRODUCT_DESCRIPTION'
export const GET_AI_PRODUCT_DESCRIPTION_SUCCESS =
	'GET_AI_PRODUCT_DESCRIPTION_SUCCESS'
export const GET_AI_PRODUCT_DESCRIPTION_FAIL = 'GET_AI_PRODUCT_DESCRIPTION_FAIL'

export const CREATE_AI_BULK_GENERATION = 'CREATE_AI_BULK_GENERATION'
export const CREATE_AI_BULK_GENERATION_SUCCESS =
	'CREATE_AI_BULK_GENERATION_SUCCESS'

export const GET_ALL_AI_BULK_GENERATION = 'GET_ALL_AI_BULK_GENERATION'
export const GET_ALL_AI_BULK_GENERATION_SUCCESS =
	'GET_ALL_AI_BULK_GENERATION_SUCCESS'
export const GET_ALL_AI_BULK_GENERATION_FAIL = 'GET_ALL_AI_BULK_GENERATION_FAIL'

export const LIST_ALL_FINETUNE_FILES = 'LIST_ALL_FINETUNE_FILES'
export const LIST_ALL_FINETUNE_FILES_SUCCESS = 'LIST_ALL_FINETUNE_FILES_SUCCESS'
export const LIST_ALL_FINETUNE_FILES_FAIL = 'LIST_ALL_FINETUNE_FILES_FAIL'

export const UPDATE_SAVED_FINETUNE_FILE = 'UPDATE_SAVED_FINETUNE_FILE'
export const UPDATE_SAVED_FINETUNE_FILE_SUCCESS =
	'UPDATE_SAVED_FINETUNE_FILE_SUCCESS'
export const UPDATE_SAVED_FINETUNE_FILE_FAIL = 'UPDATE_SAVED_FINETUNE_FILE_FAIL'

export const SEND_FINETUNE_FILE_TO_OPENAI = 'SEND_FINETUNE_FILE_TO_OPENAI'
export const SEND_FINETUNE_FILE_TO_OPENAI_SUCCESS =
	'SEND_FINETUNE_FILE_TO_OPENAI_SUCCESS'
export const SEND_FINETUNE_FILE_TO_OPENAI_FAIL =
	'SEND_FINETUNE_FILE_TO_OPENAI_FAIL'

export const GET_SAVED_FINETUNE_FILE = 'GET_SAVED_FINETUNE_FILE'
export const GET_SAVED_FINETUNE_FILE_SUCCESS = 'GET_SAVED_FINETUNE_FILE_SUCCESS'
export const GET_SAVED_FINETUNE_FILE_FAIL = 'GET_SAVED_FINETUNE_FILE_FAIL'

export const GET_POSTS_AI_CONTENT = 'GET_POSTS_AI_CONTENT'
export const GET_POSTS_AI_CONTENT_SUCCESS = 'GET_POSTS_AI_CONTENT_SUCCESS'
export const GET_POSTS_AI_CONTENT_FAIL = 'GET_POSTS_AI_CONTENT_FAIL'

export const GET_AI_HEADLINES = 'GET_AI_HEADLINES'
export const GET_AI_HEADLINES_SUCCESS = 'GET_AI_HEADLINES_SUCCESS'
export const GET_AI_HEADLINES_FAIL = 'GET_AI_HEADLINES_FAIL'

export const POST_GOOGLE_ADS_HEADLINES = 'POST_GOOGLE_ADS_HEADLINES'

export const POST_GOOGLE_ADS_DESCRIPTION = 'POST_GOOGLE_ADS_DESCRIPTION'

export const CHANGE_AI_OPTION_KEYWORD = 'CHANGE_AI_OPTION_KEYWORD'
export const CHANGE_AI_HEADLINE_KEYWORD = 'CHANGE_AI_HEADLINE_KEYWORD'
export const CHANGE_AI_CREATED_CONTENT = 'CHANGE_AI_CREATED_CONTENT'

export const GET_QUOTA_LIMIT = 'GET_QUOTA_LIMIT'
export const GET_QUOTA_LIMIT_SUCCESS = 'GET_QUOTA_LIMIT_SUCCESS'
export const GET_QUOTA_LIMIT_FAIL = 'GET_QUOTA_LIMIT_FAIL'

export const CHANGE_AI_FIELD = 'CHANGE_AI_FIELD'

export const GET_CONTENT_IN_BULK = 'GET_CONTENT_IN_BULK'
export const GET_CONTENT_IN_BULK_SUCCESS = 'GET_CONTENT_IN_BULK_SUCCESS'
export const GET_CONTENT_IN_BULK_FAIL = 'GET_CONTENT_IN_BULK_FAIL'

export const GET_LINKEDIN_POSTS = 'GET_LINKEDIN_POSTS'
export const GET_LINKEDIN_POSTS_SUCCESS = 'GET_LINKEDIN_POSTS_SUCCESS'
export const GET_LINKEDIN_POSTS_FAIL = 'GET_LINKEDIN_POSTS_FAIL'

export const GET_LINKEDIN_SCHEDULED_POSTS_ALL =
	'GET_LINKEDIN_SCHEDULED_POSTS_ALL'
export const GET_LINKEDIN_SCHEDULED_POSTS_ALL_SUCCESS =
	'GET_LINKEDIN_SCHEDULED_POSTS_ALL_SUCCESS'
export const GET_LINKEDIN_SCHEDULED_POSTS_ALL_FAIL =
	'GET_LINKEDIN_SCHEDULED_POSTS_ALL_FAIL'

export const CREATE_LINKEDIN_POST = 'CREATE_LINKEDIN_POST'
export const CREATE_LINKEDIN_POST_SUCCESS = 'CREATE_LINKEDIN_POST_SUCCESS'
export const CREATE_LINKEDIN_POST_FAIL = 'CREATE_LINKEDIN_POST_FAIL'

export const CREATE_LINKEDIN_SCHEDULED_POST = 'CREATE_LINKEDIN_SCHEDULED_POST'
export const CREATE_LINKEDIN_SCHEDULED_POST_SUCCESS =
	'CREATE_LINKEDIN_SCHEDULED_POST_SUCCESS'
export const CREATE_LINKEDIN_SCHEDULED_POST_FAIL =
	'CREATE_LINKEDIN_SCHEDULED_POST_FAIL'

export const CREATE_LINKEDIN_IMAGE_POST = 'CREATE_LINKEDIN_IMAGE_POST'
export const CREATE_LINKEDIN_IMAGE_POST_SUCCESS =
	'CREATE_LINKEDIN_IMAGE_POST_SUCCESS'
export const CREATE_LINKEDIN_IMAGE_POST_FAIL = 'CREATE_LINKEDIN_IMAGE_POST_FAIL'

export const CREATE_LINKEDIN_IMAGE_SCHEDULED_POST =
	'CREATE_LINKEDIN_IMAGE_SCHEDULED_POST'
export const CREATE_LINKEDIN_IMAGE_SCHEDULED_POST_SUCCESS =
	'CREATE_LINKEDIN_IMAGE_SCHEDULED_POST_SUCCESS'
export const CREATE_LINKEDIN_IMAGE_SCHEDULED_POST_FAIL =
	'CREATE_LINKEDIN_IMAGE_SCHEDULED_POST_FAIL'

export const GET_LINKEDIN_POST_BY_ID = 'GET_LINKEDIN_POST_BY_ID'
export const GET_LINKEDIN_POST_BY_ID_SUCCESS = 'GET_LINKEDIN_POST_BY_ID_SUCCESS'
export const GET_LINKEDIN_POST_BY_ID_FAIL = 'GET_LINKEDIN_POST_BY_ID_FAIL'

export const GET_LINKEDIN_SCHEDULED_POST_BY_ID =
	'GET_LINKEDIN_SCHEDULED_POST_BY_ID'
export const GET_LINKEDIN_SCHEDULED_POST_BY_ID_SUCCESS =
	'GET_LINKEDIN_SCHEDULED_POST_BY_ID_SUCCESS'
export const GET_LINKEDIN_SCHEDULED_POST_BY_ID_FAIL =
	'GET_LINKEDIN_SCHEDULED_POST_BY_ID_FAIL'

export const DELETE_LINKEDIN_POST_BY_ID = 'DELETE_LINKEDIN_POST_BY_ID'
export const DELETE_LINKEDIN_POST_BY_ID_SUCCESS =
	'DELETE_LINKEDIN_POST_BY_ID_SUCCESS'
export const DELETE_LINKEDIN_POST_BY_ID_FAIL = 'DELETE_LINKEDIN_POST_BY_ID_FAIL'

export const DELETE_LINKEDIN_SCHEDULED_POST_BY_ID =
	'DELETE_LINKEDIN_SCHEDULED_POST_BY_ID'
export const DELETE_LINKEDIN_SCHEDULED_POST_BY_ID_SUCCESS =
	'DELETE_LINKEDIN_SCHEDULED_POST_BY_ID_SUCCESS'
export const DELETE_LINKEDIN_SCHEDULED_POST_BY_ID_FAIL =
	'DELETE_LINKEDIN_SCHEDULED_POST_BY_ID_FAIL'

export const UPDATE_LINKEDIN_POST_BY_ID = 'UPDATE_LINKEDIN_POST_BY_ID'
export const UPDATE_LINKEDIN_POST_BY_ID_SUCCESS =
	'UPDATE_LINKEDIN_POST_BY_ID_SUCCESS'
export const UPDATE_LINKEDIN_POST_BY_ID_FAIL = 'UPDATE_LINKEDIN_POST_BY_ID_FAIL'

export const UPDATE_LINKEDIN_SCHEDULED_POST_BY_ID =
	'UPDATE_LINKEDIN_SCHEDULED_POST_BY_ID'
export const UPDATE_LINKEDIN_SCHEDULED_POST_BY_ID_SUCCESS =
	'UPDATE_LINKEDIN_SCHEDULED_POST_BY_ID_SUCCESS'
export const UPDATE_LINKEDIN_SCHEDULED_POST_BY_ID_FAIL =
	'UPDATE_LINKEDIN_SCHEDULED_POST_BY_ID_FAIL'

export const SCHEDULE_FACEBOOK_POST = 'SCHEDULE_FACEBOOK_POST'
export const SCHEDULE_FACEBOOK_POST_SUCCESS = 'SCHEDULE_FACEBOOK_POST_SUCCESS'
export const SCHEDULE_FACEBOOK_POST_FAIL = 'SCHEDULE_FACEBOOK_POST_FAIL'

export const GET_INSTAGRAM_ACCOUNT = 'GET_INSTAGRAM_ACCOUNT'
export const GET_INSTAGRAM_ACCOUNT_SUCCESS = 'GET_INSTAGRAM_ACCOUNT_SUCCESS'
export const GET_INSTAGRAM_ACCOUNT_FAIL = 'GET_INSTAGRAM_ACCOUNT_FAIL'

export const PUBLISH_INSTAGRAM_POST = 'PUBLISH_INSTAGRAM_POST'
export const PUBLISH_INSTAGRAM_POST_SUCCESS = 'PUBLISH_INSTAGRAM_POST_SUCCESS'
export const PUBLISH_INSTAGRAM_POST_FAIL = 'PUBLISH_INSTAGRAM_POST_FAIL'

export const SCHEDULE_INSTAGRAM_POST = 'SCHEDULE_INSTAGRAM_POST'
export const SCHEDULE_INSTAGRAM_POST_SUCCESS = 'SCHEDULE_INSTAGRAM_POST_SUCCESS'
export const SCHEDULE_INSTAGRAM_POST_FAIL = 'SCHEDULE_INSTAGRAM_POST_FAIL'

export const GET_FACEBOOK_POST_ATTACHMENTS = 'GET_FACEBOOK_POST_ATTACHMENTS'
export const GET_FACEBOOK_POST_ATTACHMENTS_SUCCESS =
	'GET_FACEBOOK_POST_ATTACHMENTS_SUCCESS'
export const GET_FACEBOOK_POST_ATTACHMENTS_FAIL =
	'GET_FACEBOOK_POST_ATTACHMENTS_FAIL'

export const EDIT_SOME_FACEBOOK_POST = 'EDIT_SOME_FACEBOOK_POST'

export const UPLOAD_FACEBOOK_PHOTO = 'UPLOAD_FACEBOOK_PHOTO'

export const EDIT_SCHEDULED_INSTAGRAM_POST = 'EDIT_SCHEDULED_INSTAGRAM_POST'

export const DELETE_FACEBOOK_POST = 'DELETE_FACEBOOK_POST'
export const DELETE_FACEBOOK_POST_SUCCESS = 'DELETE_FACEBOOK_POST_SUCCESS'
export const DELETE_FACEBOOK_POST_FAIL = 'DELETE_FACEBOOK_POST_FAIL'

export const DELETE_INSTAGRAM_SCHEDULED_POST = 'DELETE_INSTAGRAM_SCHEDULED_POST'
export const DELETE_INSTAGRAM_SCHEDULED_POST_SUCCESS =
	'DELETE_INSTAGRAM_SCHEDULED_POST_SUCCESS'
export const DELETE_INSTAGRAM_SCHEDULED_POST_FAIL =
	'DELETE_INSTAGRAM_SCHEDULED_POST_FAIL'

export const UPLOAD_SCHEDULED_INSTAGRAM_PHOTO =
	'UPLOAD_SCHEDULED_INSTAGRAM_PHOTO'

export const RESET_DRAFT_SOME_POST = 'RESET_DRAFT_SOME_POST'

export const CHANGE_FIELD = 'CHANGE_FIELD'

export const START_SOME_POST_SUGGESTIONS_LOADING =
	'START_SOME_POST_SUGGESTIONS_LOADING'

export const STOP_SOME_POST_SUGGESTIONS_LOADING =
	'STOP_SOME_POST_SUGGESTIONS_LOADING'

export const SET_STATUS_CODE = 'SET_STATUS_CODE'

// WP tools related types
export const GET_WP_POSTS = 'GET_WP_POSTS'
export const GET_WP_POSTS_SUCCESS = 'GET_WP_POSTS_SUCCESS'
export const GET_WP_POSTS_FAIL = 'GET_WP_POSTS_FAIL'

export const GET_WP_PAGES = 'GET_WP_PAGES'
export const GET_WP_PAGES_SUCCESS = 'GET_WP_PAGES_SUCCESS'
export const GET_WP_PAGES_FAIL = 'GET_WP_PAGES_FAIL'

export const GET_WP_ITEM_BY_ID = 'GET_WP_ITEM_BY_ID'
export const GET_WP_ITEM_BY_ID_SUCCESS = 'GET_WP_ITEM_BY_ID_SUCCESS'
export const GET_WP_ITEM_BY_ID_FAIL = 'GET_WP_ITEM_BY_ID_FAIL'

export const UPDATE_WP_ITEM_BY_ID = 'UPDATE_WP_ITEM_BY_ID'
export const UPDATE_WP_ITEM_BY_ID_SUCCESS = 'UPDATE_WP_ITEM_BY_ID_SUCCESS'
export const UPDATE_WP_ITEM_BY_ID_FAIL = 'UPDATE_WP_ITEM_BY_ID_FAIL'

export const CREATE_WP_ITEM_BY_TYPE = 'CREATE_WP_ITEM_BY_TYPE'
export const CREATE_WP_ITEM_BY_TYPE_SUCCESS = 'CREATE_WP_ITEM_BY_TYPE_SUCCESS'
export const CREATE_WP_ITEM_BY_TYPE_FAIL = 'CREATE_WP_ITEM_BY_TYPE_FAIL'

export const CHANGE_WP_CURRENT_PAGE_BY_TYPE = 'CHANGE_WP_CURRENT_PAGE_BY_TYPE'

export const RESET_WP_STATE = 'RESET_WP_STATE'
export const RESET_WP_SELECTED_ITEM = 'RESET_WP_SELECTED_ITEM'
export const CHANGE_WP_SELECTED_ITEM_TITLE = 'CHANGE_WP_SELECTED_ITEM_TITLE'
export const SET_WP_SELECTED_ITEM = 'SET_WP_SELECTED_ITEM'
export const CREATE_TASK = 'CREATE_TASK'
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS'
export const CREATE_TASK_FAIL = 'CREATE_TASK_FAIL'

export const CREATE_CUSTOM_TASK = 'CREATE_CUSTOM_TASK'
export const CREATE_CUSTOM_TASK_SUCCESS = 'CREATE_CUSTOM_TASK_SUCCESS'
export const CREATE_CUSTOM_TASK_FAIL = 'CREATE_CUSTOM_TASK_FAIL'

export const GET_ALL_TASKS = 'GET_ALL_TASKS'
export const GET_ALL_TASKS_SUCCESS = 'GET_ALL_TASKS_SUCCESS'
export const GET_ALL_TASKS_FAIL = 'GET_ALL_TASKS_FAIL'

export const UPDATE_TASK = 'UPDATE_TASK'
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS'
export const UPDATE_TASK_FAIL = 'UPDATE_TASK_FAIL'

export const RESET_TASK_STATE = 'RESET_TASK_STATE'

export const CREATE_COMPANY = 'CREATE_COMPANY'
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS'
export const CREATE_COMPANY_FAIL = 'CREATE_COMPANY_FAIL'

export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL'

export const UPDATE_COMPANY_USER = 'UPDATE_COMPANY_USER'
export const UPDATE_COMPANY_USER_SUCCESS = 'UPDATE_COMPANY_USER_SUCCESS'
export const UPDATE_COMPANY_USER_FAIL = 'UPDATE_COMPANY_USER_FAIL'

export const INVITE_USER_TO_COMPANY = 'INVITE_USER_TO_COMPANY'
export const INVITE_USER_TO_COMPANY_SUCCESS = 'INVITE_USER_TO_COMPANY_SUCCESS'
export const INVITE_USER_TO_COMPANY_FAIL = 'INVITE_USER_TO_COMPANY_FAIL'

export const INVITE_CONTRIBUTOR_TO_PROJECT = 'INVITE_CONTRIBUTOR_TO_PROJECT'
export const INVITE_CONTRIBUTOR_TO_PROJECT_SUCCESS =
	'INVITE_CONTRIBUTOR_TO_PROJECT_SUCCESS'
export const INVITE_CONTRIBUTOR_TO_PROJECT_FAIL =
	'INVITE_CONTRIBUTOR_TO_PROJECT_FAIL'

export const GET_CONTRIBUTOR_LIST = 'GET_CONTRIBUTOR_LIST'
export const GET_CONTRIBUTOR_LIST_SUCCESS = 'GET_CONTRIBUTOR_LIST_SUCCESS'
export const GET_CONTRIBUTOR_LIST_FAIL = 'GET_CONTRIBUTOR_LIST_FAIL'

export const UPDATE_CONTRIBUTOR_DATA = 'UPDATE_CONTRIBUTOR_DATA'
export const UPDATE_CONTRIBUTOR_DATA_SUCCESS = 'UPDATE_CONTRIBUTOR_DATA_SUCCESS'
export const UPDATE_CONTRIBUTOR_DATA_FAIL = 'UPDATE_CONTRIBUTOR_DATA_FAIL'

export const DELETE_CONTRIBUTOR_DATA = 'DELETE_CONTRIBUTOR_DATA'
export const DELETE_CONTRIBUTOR_DATA_SUCCESS = 'DELETE_CONTRIBUTOR_DATA_SUCCESS'
export const DELETE_CONTRIBUTOR_DATA_FAIL = 'DELETE_CONTRIBUTOR_DATA_FAIL'

export const GET_PRODUCT_REFERENCE = 'GET_PRODUCT_REFERENCE'
export const GET_PRODUCT_REFERENCE_SUCCESS = 'GET_PRODUCT_REFERENCE_SUCCESS'
export const GET_PRODUCT_REFERENCE_FAIL = 'GET_PRODUCT_REFERENCE_FAIL'

export const SAVE_PRODUCT_REFERENCE = 'SAVE_PRODUCT_REFERENCE'
export const SAVE_PRODUCT_REFERENCE_SUCCESS = 'SAVE_PRODUCT_REFERENCE_SUCCESS'
export const SAVE_PRODUCT_REFERENCE_FAIL = 'SAVE_PRODUCT_REFERENCE_FAIL'

export const GET_PROJECT_USER = 'GET_PROJECT_USER'
export const GET_PROJECT_USER_SUCCESS = 'GET_PROJECT_USER_SUCCESS'
export const GET_PROJECT_USER_FAIL = 'GET_PROJECT_USER_FAIL'
// Comments

export const RESET_COMMENT_STATE = 'RESET_COMMENT_STATE'

export const GET_ALL_COMMENTS_BY_TYPE_AND_ID = 'GET_ALL_COMMENTS_BY_TYPE_AND_ID'
export const GET_ALL_COMMENTS_BY_TYPE_AND_ID_SUCCESS =
	'GET_ALL_COMMENTS_BY_TYPE_AND_ID_SUCCESS'
export const GET_ALL_COMMENTS_BY_TYPE_AND_ID_FAIL =
	'GET_ALL_COMMENTS_BY_TYPE_AND_ID_FAIL'

export const CREATE_COMMENT_BY_TASK_ID = 'CREATE_COMMENT_BY_TASK_ID'
export const CREATE_COMMENT_BY_TASK_ID_SUCCESS =
	'CREATE_COMMENT_BY_TASK_ID_SUCCESS'
export const CREATE_COMMENT_BY_TASK_ID_FAIL = 'CREATE_COMMENT_BY_TASK_ID_FAIL'

export const CREATE_COMMENT_BY_SUGGESTION_ID = 'CREATE_COMMENT_BY_SUGGESTION_ID'
export const CREATE_COMMENT_BY_SUGGESTION_ID_SUCCESS =
	'CREATE_COMMENT_BY_SUGGESTION_ID_SUCCESS'
export const CREATE_COMMENT_BY_SUGGESTION_ID_FAIL =
	'CREATE_COMMENT_BY_SUGGESTION_ID_FAIL'

export const DELETE_COMMENT_BY_ID = 'DELETE_COMMENT_BY_ID'
export const DELETE_COMMENT_BY_ID_SUCCESS = 'DELETE_COMMENT_BY_ID_SUCCESS'
export const DELETE_COMMENT_BY_ID_FAIL = 'DELETE_COMMENT_BY_ID_FAIL'

export const UPDATE_COMMENT_BY_ID = 'UPDATE_COMMENT_BY_ID'
export const UPDATE_COMMENT_BY_ID_SUCCESS = 'UPDATE_COMMENT_BY_ID_SUCCESS'
export const UPDATE_COMMENT_BY_ID_FAIL = 'UPDATE_COMMENT_BY_ID_FAIL'

export const START_APP_LOADING = 'START_APP_LOADING'
export const STOP_APP_LOADING = 'STOP_APP_LOADING'
export const SET_APP_LOADING_MESSAGE = 'SET_APP_LOADING_MESSAGE'

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
// Rank predictor
export const CREATE_NEW_RANK_PREDICTION = 'CREATE_NEW_RANK_PREDICTION'
export const CREATE_NEW_RANK_PREDICTION_SUCCESS =
	'CREATE_NEW_RANK_PREDICTION_SUCCESS'
export const CREATE_NEW_RANK_PREDICTION_FAIL = 'CREATE_NEW_RANK_PREDICTION_FAIL'

export const GET_RANK_PREDICTION_BY_ID = 'GET_RANK_PREDICTION_BY_ID'
export const GET_RANK_PREDICTION_BY_ID_SUCCESS =
	'GET_RANK_PREDICTION_BY_ID_SUCCESS'
export const GET_RANK_PREDICTION_BY_ID_FAIL = 'GET_RANK_PREDICTION_BY_ID_FAIL'

export const GET_RANK_PREDICTIONS_BY_COMPANY_ID =
	'GET_RANK_PREDICTIONS_BY_COMPANY_ID'
export const GET_RANK_PREDICTIONS_BY_COMPANY_ID_SUCCESS =
	'GET_RANK_PREDICTIONS_BY_COMPANY_ID_SUCCESS'
export const GET_RANK_PREDICTIONS_BY_COMPANY_ID_FAIL =
	'GET_RANK_PREDICTIONS_BY_COMPANY_ID_FAIL'

export const CHECK_RANK_PREDICTION_BY_ID = 'CHECK_RANK_PREDICTION_BY_ID'
export const CHECK_RANK_PREDICTION_BY_ID_SUCCESS =
	'CHECK_RANK_PREDICTION_BY_ID_SUCCESS'
export const CHECK_RANK_PREDICTION_BY_ID_FAIL =
	'CHECK_RANK_PREDICTION_BY_ID_FAIL'

export const UPDATE_CHAT_STREAM = 'UPDATE_CHAT_STREAM'
export const UPDATE_CHAT_STREAM_SUCCESS = 'UPDATE_CHAT_STREAM_SUCCESS'
export const UPDATE_CHAT_STREAM_FAIL = 'UPDATE_CHAT_STREAM_FAIL'

export const RESET_CHAT_STREAM = 'RESET_CHAT_STREAM'

export const CREATE_MERCHANT_PRODUCT_REQUEST = 'CREATE_MERCHANT_PRODUCT_REQUEST'
export const CREATE_MERCHANT_PRODUCT_REQUEST_SUCCESS =
	'CREATE_MERCHANT_PRODUCT_REQUEST_SUCCESS'
export const CREATE_MERCHANT_PRODUCT_REQUEST_FAIL =
	'CREATE_MERCHANT_PRODUCT_REQUEST_FAIL'

export const GET_ALL_PRODUCT_PRICE = 'GET_ALL_PRODUCT_PRICE'
export const GET_ALL_PRODUCT_PRICE_SUCCESS = 'GET_ALL_PRODUCT_PRICE_SUCCESS'
export const GET_ALL_PRODUCT_PRICE_FAIL = 'GET_ALL_PRODUCT_PRICE_FAIL'

export const DELETE_PRODUCT_TRACKING = 'DELETE_PRODUCT_TRACKING'
export const DELETE_PRODUCT_TRACKING_SUCCESS = 'DELETE_PRODUCT_TRACKING_SUCCESS'
export const DELETE_PRODUCT_TRACKING_FAIL = 'DELETE_PRODUCT_TRACKING_FAIL'

export const UPDATE_TRACKING_DATE = 'UPDATE_TRACKING_DATE'
export const UPDATE_TRACKING_DATE_SUCCESS = 'UPDATE_TRACKING_DATE_SUCCESS'
export const UPDATE_TRACKING_DATE_FAIL = 'UPDATE_TRACKING_DATE_FAIL'

export const GET_PRODUCT_CATEGORY_TREE_MAP = 'GET_PRODUCT_CATEGORY_TREE_MAP'
export const GET_PRODUCT_CATEGORY_TREE_MAP_SUCCESS =
	'GET_PRODUCT_CATEGORY_TREE_MAP_SUCCESS'
export const GET_PRODUCT_CATEGORY_TREE_MAP_FAIL =
	'GET_PRODUCT_CATEGORY_TREE_MAP_FAIL'

export const TRANSLATE_CONTENT = 'TRANSLATE_CONTENT'
export const TRANSLATE_CONTENT_SUCCESS = 'TRANSLATE_CONTENT_SUCCESS'
export const TRANSLATE_CONTENT_FAIL = 'TRANSLATE_CONTENT_FAIL'

export const FETCH_DETAILS_DATA = 'FETCH_DETAILS_DATA'
export const FETCH_DETAILS_DATA_SUCCESS = 'FETCH_DETAILS_DATA_SUCCESS'
export const FETCH_DETAILS_DATA_FAIL = 'FETCH_DETAILS_DATA_FAIL'
