import React, { ReactElement, useState } from 'react'
import { FacebookAdsItem } from '../../../constants'
import { isNotEmptyArray } from '../../../helpers/fnHelpers'
import FacebookAdSet from './FacebookAdSet'
import { useTranslation } from 'react-i18next'
interface Props {
	campaign: FacebookAdsItem
	adSets: FacebookAdsItem[]
	ads: FacebookAdsItem[]
}

export default function FacebookAdsCampaign({
	campaign,
	adSets,
	ads,
}: Props): ReactElement {
	const { t } = useTranslation()
	const [showDetails, setShowDetails] = useState<boolean>(false)
	const valuesToRender = [
		{
			text: t('ads visualization.budget'),
			value: `${campaign.dailyBudget || 0}€/D`,
		},
		{
			text: t('ads visualization.spend'),
			value: `${campaign.spend || 0}€`,
		},
		{
			text: t('ads visualization.frequency'),
			value: campaign.frequency || 0,
		},
		{
			text: t('ads visualization.cpm'),
			value: campaign.CPM || 0,
		},
		{
			text: t('ads visualization.price per conversion'),
			value: `${campaign.costPerConversion || 0}€`,
		},
		{
			text: t('ads visualization.impressions'),
			value: `${campaign.impressions || 0}`,
		},
		{
			text: t('ads visualization.reach'),
			value: `${campaign.reach || 0}`,
		},
		{
			text: t('ads visualization.roas'),
			value: `${campaign.ROAS || 0}`,
		},
		{
			text: t('ads visualization.clicks'),
			value: `${campaign.clicks || 0}`,
		},
		{
			text: t('ads visualization.conversions'),
			value: `${campaign.conversions || 0}`,
		},
		{
			text: t('ads visualization.conversion value'),
			value: `${campaign.conversionValue || 0}€`,
		},
		{
			text: t('ads visualization.conversion rate'),
			value: `${campaign.conversionRate || 0}%`,
		},
		{
			text: t('ads visualization.average cpc'),
			value: `${campaign.CPC || 0}€`,
		},
	]

	const renderValues = ({
		text,
		value,
	}: {
		text: string
		value: string | number | null
	}) => {
		return (
			<div className='ads-visualisation-page__content--container__adGroups--item--keyword-item border'>
				<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
					{text}
				</p>

				<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
					{value}
				</p>
			</div>
		)
	}
	const renderContent = () => {
		return <div>{valuesToRender.map(renderValues)}</div>
	}
	if (campaign.size === 'large') {
		return (
			<div
				key={campaign.campaignId}
				className={`ads-visualisation-page__content--container__campaigns--container ${
					campaign.color
				} ${campaign.size}  ${campaign.isPhantomCampaign ? 'invisible' : ''}`}
			>
				<div
					className={`ads-visualisation-page__content--container__campaigns--item ${
						campaign.color
					} ${campaign.size} ${campaign.isPhantomCampaign ? 'invisible' : ''}`}
				>
					<div
						className={
							'ads-visualisation-page__content--container__campaigns--item-header'
						}
					>
						<h6 onClick={() => campaign.onClick(campaign)}>{campaign.name}</h6>
						<>
							<span
								className={
									'ads-visualisation-page__content--container__campaigns--item-header edit'
								}
								onClick={() => campaign.onEditClick(campaign)}
							>
								<p>View</p>
							</span>
						</>
					</div>
					{renderContent()}
				</div>
				{isNotEmptyArray(adSets) && (
					<div
						className={`ads-visualisation-page__content--container__adGroups--items ${campaign.size}`}
					>
						{adSets.map((adSet, index) => (
							<FacebookAdSet
								key={index}
								size={campaign.size ? campaign.size : 'medium'}
								adSet={adSet}
								ads={ads.filter(
									(ad: FacebookAdsItem) => ad.adSetId === adSet.adSetId,
								)}
							/>
						))}
					</div>
				)}
			</div>
		)
	} else if (campaign.size === 'medium') {
		return (
			<div
				key={campaign.campaignId}
				className={`ads-visualisation-page__content--container__campaigns--container  ${campaign.size}`}
			>
				<div
					className={`ads-visualisation-page__content--container__campaigns--item ${
						campaign.color
					} ${campaign.isPhantomCampaign ? 'invisible' : ''} ${
						campaign.size
					}  ${showDetails ? 'open' : ''}`}
				>
					<div
						className={
							'ads-visualisation-page__content--container__campaigns--item-header'
						}
					>
						<span />
						<h6 onClick={() => campaign.onClick(campaign)}>{campaign.name}</h6>
						<>
							<span
								onClick={() => campaign.onEditClick(campaign)}
								className={
									'ads-visualisation-page__content--container__campaigns--item-header edit'
								}
							>
								<p>View</p>
							</span>
						</>
					</div>
					<div
						className={`ads-visualisation-page__content--container__campaigns--item--hidden medium ${
							showDetails ? 'open' : ''
						}`}
					>
						{renderContent()}
					</div>
					<>
						<p
							className='ads-visualisation-page__content--container__adGroups--item--keyword--view-more'
							onClick={() => setShowDetails((old: boolean) => !old)}
						>
							{showDetails
								? `${t('common.view less')} -`
								: `${t('common.view more')} +`}
						</p>
					</>
				</div>
				{isNotEmptyArray(adSets) && (
					<div
						className={`ads-visualisation-page__content--container__adGroups--items ${campaign.size}`}
					>
						{adSets.map((adSet, index) => (
							<FacebookAdSet
								key={index}
								size={campaign.size ? campaign.size : 'medium'}
								adSet={adSet}
								ads={ads.filter(
									(ad: FacebookAdsItem) => ad.adSetId === adSet.adSetId,
								)}
							/>
						))}
					</div>
				)}
			</div>
		)
	} else {
		return (
			<div
				key={campaign.campaignId}
				className={`ads-visualisation-page__content--container__campaigns--container  ${campaign.size}`}
			>
				<div
					className={`ads-visualisation-page__content--container__campaigns--item ${
						campaign.color
					} ${campaign.isPhantomCampaign ? 'invisible' : ''} ${
						campaign.size
					}  ${showDetails ? 'open' : ''}`}
				>
					<div
						className={
							'ads-visualisation-page__content--container__campaigns--item-header'
						}
					>
						<span />
						<h6 onClick={() => campaign.onClick(campaign)}>{campaign.name}</h6>
						<>
							<span
								className={
									'ads-visualisation-page__content--container__campaigns--item-header edit'
								}
								onClick={() => campaign.onEditClick(campaign)}
							>
								<p>View</p>
							</span>
						</>
					</div>
					<div
						className={`ads-visualisation-page__content--container__campaigns--item--hidden  ${
							showDetails ? 'open' : ''
						}`}
					>
						{renderContent()}
					</div>
					<>
						<p
							className='ads-visualisation-page__content--container__adGroups--item--keyword--view-more'
							onClick={() => setShowDetails((old: boolean) => !old)}
						>
							{showDetails
								? `${t('common.view less')} -`
								: `${t('common.view more')} +`}
						</p>
					</>
				</div>
				{isNotEmptyArray(adSets) && (
					<div
						className={`ads-visualisation-page__content--container__adGroups--items ${campaign.size}`}
					>
						{adSets.map((adSet, index) => (
							<FacebookAdSet
								key={index}
								size={campaign.size ? campaign.size : 'medium'}
								adSet={adSet}
								ads={ads.filter(
									(ad: FacebookAdsItem) => ad.adSetId === adSet.adSetId,
								)}
							/>
						))}
					</div>
				)}
			</div>
		)
	}
}
